import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import Table from "antd/es/table";
import PageHeader from "antd/lib/page-header";
import { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useIsMounted } from "../../../../@vodea/utilities/hooks";
import Accessible from "../../../../@vodea/vodea-ui/components/Accessible";
import VuiContainer from "../../../../@vodea/vodea-ui/components/Container";
import { ACCESS_PERMISSION } from "../../../../constant";
import {
  handleBackendError,
  overideTableSortOrder,
} from "../../../../functions/global";
import ReportRepository from "../../../../repositories/ReportRepostitory";
import "./style.less";

var fileDownload = require("js-file-download");
var qs = require("qs");

const title = "Report";

const AppReportView: React.FC<any> = () => {
  const { year, month } = useParams();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const tablePage = useState<number>(parseInt(searchParams.get("page") || "1"));
  const tablePerPage = useState<number>(
    parseInt(searchParams.get("perPage") || "10")
  );
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const tableSort = useState<{
    sortField: any;
    sortOrder: any;
  }>({
    sortField: searchParams.get("sortField"),
    sortOrder: searchParams.get("sortOrder"),
  });
  const tableData = useState([]);
  const totalData = useState<number>(0);

  const getTableData = async (reset: boolean = false) => {
    setTableLoading(true);

    if (reset) tablePage.set(1);

    let params = {
      page: tablePage.get(),
      order_by: tableSort.sortField.get(),
      sorted_by: tableSort.sortOrder.get(),
      per_page: tablePerPage.get(),
    };

    if (!params.order_by) {
      params.order_by = "created_at";
      params.sorted_by = "desc";
    }

    await ReportRepository.show(year, month)
      .then((res: AxiosResponse) => {
        if (res.data.data) {
          tableData.set(res.data.data);
        }

        totalData.set(res.data?.meta?.total || 0);

        setTableLoading(false);
      })
      .catch((e: AxiosError) => {
        setTableLoading(false);
      });

    setTableLoading(false);
  };

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    tablePage.set(pagination.current);
    tablePerPage.set(pagination.pageSize);

    if (sorter.order) {
      const sortOrder = overideTableSortOrder(sorter.order);
      tableSort.sortField.set(sorter.field);
      tableSort.sortOrder.set(sortOrder);
    } else {
      tableSort.sortField.set(null);
      tableSort.sortOrder.set(null);
    }

    setFilterDataToQuery();
  };

  useEffect(() => {
    if (!isMounted) {
      setFilterDataToQuery();
    }
    // eslint-disable-next-line
  }, []);

  const getFilterDataFromQuery = () => {
    getTableData();
  };

  useMemo(() => {
    getFilterDataFromQuery();
    // eslint-disable-next-line
  }, []);

  const setFilterDataToQuery = () => {
    let params = {};
    let reset = false;

    if (tablePage.get() !== 1) {
      if (reset) {
        Object.assign(params, {
          page: 1,
        });
      } else {
        Object.assign(params, {
          page: tablePage.get(),
        });
      }
    }

    if (tablePerPage.get() !== 10) {
      Object.assign(params, {
        perPage: tablePerPage.get(),
      });
    }

    if (tableSort.sortField.get()) {
      Object.assign(params, {
        sortField: tableSort.sortField.get(),
      });
    }

    if (tableSort.sortOrder.get()) {
      Object.assign(params, {
        sortOrder: tableSort.sortOrder.get(),
      });
    }

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("");
    }

    getTableData(reset);
  };

  const columns: any = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "date" && tableSort.sortOrder.get(),
      render: (text: any) => {
        return moment(text).format("D MMM YYYY");
      },
    },
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      key: "patient_name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "patient_name" &&
        tableSort.sortOrder.get(),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "email" && tableSort.sortOrder.get(),
    },
    {
      title: "Date of Birth",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "date_of_birth" &&
        tableSort.sortOrder.get(),
      render: (value: string) =>
        value ? moment(value).format("DD MMM YYYY") : "-",
    },
    {
      title: "Mobile Phone",
      dataIndex: "phone_number",
      key: "phone_number",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "phone_number" &&
        tableSort.sortOrder.get(),
      render: (value: string, record: any) =>
        `${record.phone_country ? `(${record.phone_country}) ` : ""}${value}`,
    },
    {
      title: "Consultation Category",
      dataIndex: "category_name",
      key: "category_name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "category_name" &&
        tableSort.sortOrder.get(),
    },
    {
      title: "Metode",
      dataIndex: "consultation_type",
      key: "consultation_type",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "consultation_type" &&
        tableSort.sortOrder.get(),
    },
    {
      title: "Consultation Time",
      dataIndex: "time",
      key: "time",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "time" && tableSort.sortOrder.get(),
    },
    {
      title: "Consultation Duration",
      dataIndex: "duration",
      key: "duration",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "duration" && tableSort.sortOrder.get(),
    },
    {
      title: "Diagnosis",
      dataIndex: "diagnose",
      key: "diagnose",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "diagnose" && tableSort.sortOrder.get(),
    },
    {
      title: "Type of Issues",
      dataIndex: "topic",
      key: "topic",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "topic" && tableSort.sortOrder.get(),
    },
    {
      title: "Psychologist",
      dataIndex: "psychologist_name",
      key: "psychologist_name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "psychologist_name" &&
        tableSort.sortOrder.get(),
    },
  ];

  const handleExport = async () => {
    let params = {
      year: year,
      month: month,
    };
    try {
      let res = await ReportRepository.export(params);
      await fileDownload(res.data, `${Date.now()} - Report .xlsx`);
    } catch (err: any) {
      handleBackendError(err);
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="report-view-container">
        <VuiContainer>
          <PageHeader
            className="default-page-header"
            onBack={() => navigate(-1)}
            title={`${title} Page Detail`}
            extra={[
              <Accessible access={ACCESS_PERMISSION.report.excel}>
                <Button key="1" type="primary" onClick={handleExport}>
                  Download
                </Button>
              </Accessible>,
            ]}
          />
          <Table
            rowKey={(record: any) =>
              record.date + record.patient_name + record.time
            }
            scroll={{ x: "max-content" }}
            bordered
            columns={columns}
            dataSource={tableData.get()}
            loading={tableLoading}
            onChange={handleTableChange}
            pagination={{
              current: tablePage.get(),
              showSizeChanger: true,
              pageSize: tablePerPage.get(),
              total: totalData.get(),
            }}
          />
        </VuiContainer>
      </div>
    </>
  );
};

export default AppReportView;
