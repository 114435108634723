import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import Input from "antd/es/input";
import PageHeader from "antd/es/page-header";
import Card from "antd/lib/card";
import Form from "antd/lib/form";
import { Col, Row } from "antd/lib/grid";
import Space from "antd/lib/space";
import Spin from "antd/lib/spin";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Accessible from "../../../@vodea/vodea-ui/components/Accessible";
import VuiContainer from "../../../@vodea/vodea-ui/components/Container";
import VuiEditor, {
  vuiEditorBaseInit,
} from "../../../@vodea/vodea-ui/components/Editor";
import VuiUploadImages from "../../../@vodea/vodea-ui/components/UploadImages";
import { ACCESS_PERMISSION, env } from "../../../constant";
import {
  handleBackendError,
  openNotification,
  toFileList,
} from "../../../functions/global";
import SettingRepository from "../../../repositories/SettingRepository";

const { TextArea } = Input;

let title = "Settings";

const AppSettings: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageTitle = `${title} Page Detail`;

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);

  const onFinish = async (data: any) => {
    loading.set(true);

    const formData = {
      ...data,
      logo_id: _.get(data, "logo_id[0].id"),
    };

    await SettingRepository.create(formData)
      .then((res: AxiosResponse) => {
        openNotification(
          "success",
          t("notification.success.updateItem", { item: title })
        );
        loading.set(false);
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    const payload = {
      with: "logo",
    };
    await SettingRepository.all(payload)
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};
        const modified: any = {
          ...data,
          logo_id: toFileList(data.logo),
        };

        form.setFieldsValue({
          ...modified,
        });
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  };

  return (
    <>
      <Helmet>
        <title>{title} Detail</title>
      </Helmet>
      <VuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
              }}
              xs={{
                span: 24,
              }}
            >
              <Card title={""} className="mb16">
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={0}
                >
                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="company_name"
                        label={"Name"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", { item: "Name" }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", { item: "Name" })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item label="Logo" name="logo_id">
                        <VuiUploadImages isUploadFirst />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="description"
                        label={"Description"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Description",
                            }),
                          },
                        ]}
                      >
                        <VuiEditor
                          disabled={disable.get()}
                          apiKey={env.getTinyApiKey()}
                          init={{
                            ...vuiEditorBaseInit,
                            placeholder: t("common.text.input", {
                              item: "Description",
                            }),
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="email"
                        label={t("common.form.email.label")}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: t("common.form.email.label"),
                            }),
                          },
                          {
                            type: "email",
                            message: t("validation.type.email", {
                              item: t("common.form.email.label"),
                            }),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("common.form.email.placeholder")}
                          size={"large"}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="office_phone_number"
                        label={"Office Phone Number"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Office Phone Number",
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Office Phone Number",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="phone_number"
                        label={"Phone Number"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Phone Number",
                            }),
                          },
                          {
                            min: 11,
                            message: t("validation.type.min", {
                              item: "Phone Number",
                              min: 11,
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Phone Number",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="whatsapp_number"
                        label={"Whatsapp Number"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Whatsapp Number",
                            }),
                          },
                          {
                            min: 11,
                            message: t("validation.type.min", {
                              item: "Whatsapp Number",
                              min: 11,
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Whatsapp Number",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="address"
                        label={"Address"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Address",
                            }),
                          },
                        ]}
                      >
                        <TextArea
                          rows={3}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Address",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="iframe_address"
                        label={"Iframe Address"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Iframe Address",
                            }),
                          },
                        ]}
                      >
                        <TextArea
                          rows={3}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Iframe Address",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="gmaps_link"
                        label={"Google Maps Link"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Google Maps Link",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Google Maps Link",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="instagram_link"
                        label={"Instagram Link"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Instagram Link",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Instagram Link",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="facebook_link"
                        label={"Facebook Link"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Facebook Link",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Facebook Link",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="youtube_link"
                        label={"Youtube Link"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Youtube Link",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Youtube Link",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="twitter_link"
                        label={"Twitter Link"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Twitter Link",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Twitter Link",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="linkedin_link"
                        label={"LinkedIn Link"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "LinkedIn Link",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "LinkedIn Link",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Space>
              </Card>
            </Col>
          </Row>

          <div className="vui-form-btn-group">
            <Button
              className="vui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Accessible access={ACCESS_PERMISSION.setting.update}>
              <Spin spinning={loading.get()}>
                <Button
                  className="vui-btn"
                  type="primary"
                  htmlType="submit"
                  size={"large"}
                >
                  Submit
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </VuiContainer>
    </>
  );
};

export default AppSettings;
