interface DefaultMap {
  [name: string]: string;
}

export const env = {
  getTinyApiKey: () => window._env_.REACT_APP_TINY_API_KEY,
};

export const SELECT_ALL_VALUE = "ALL";

export const INTERNAL_NOTES_TYPE_SHORT_ANSWER = "SHORT_ANSWER";
export const INTERNAL_NOTES_TYPE_MULTIPLE_CHOICE = "MULTIPLE_CHOICE";
export const INTERNAL_NOTES_TYPE_CHECK_BOX = "CHECK_BOX";
export const INTERNAL_NOTES_TYPE_DROPDOWN = "DROPDOWN";

export const INTERNAL_NOTES_TYPE_OPTION_LABELS: DefaultMap = {
  [INTERNAL_NOTES_TYPE_SHORT_ANSWER]: "Text",
  [INTERNAL_NOTES_TYPE_MULTIPLE_CHOICE]: "Multiple Choices",
  [INTERNAL_NOTES_TYPE_CHECK_BOX]: "Checkboxes",
  [INTERNAL_NOTES_TYPE_DROPDOWN]: "Dropdown",
};

export const INTERNAL_NOTES_TYPE_OPTIONS = [
  INTERNAL_NOTES_TYPE_SHORT_ANSWER,
  INTERNAL_NOTES_TYPE_MULTIPLE_CHOICE,
  INTERNAL_NOTES_TYPE_CHECK_BOX,
  INTERNAL_NOTES_TYPE_DROPDOWN,
];

export const COST_INFORMATION_DURATION_OPTIONS = [
  {
    label: "60 Menit",
    value: 60,
  },
  {
    label: "90 Menit",
    value: 90,
  },
];

export const recentFilterKey = {
  tags: "filter.tags",
  expertises: "filter.expertises",
  psychologist: "filter.psychologist",
  bookingStatus: "filter.bookingStatus",
  customer: "filter.customer",
  sessionType: "filter.sessionType",
  type: "filter.type",
  psychologistScheduleStatus: "filter.psychologistScheduleStatus",
};

export const GENDER_OPTIONS = [
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Male",
    value: "Male",
  },
];

export const SCHEDULE_STATUS = {
  notStarted: {
    id: 3,
    name: "Not Started",
  },
  onGoing: {
    id: 4,
    name: "Ongoing",
  },
  done: {
    id: 5,
    name: "Session Ends",
  },
};

export const QUESTION_FORM_TYPE = {
  shortAnswer: "SHORT_ANSWER",
  paragraph: "PARAGRAPH",
  multipleChoice: "MULTIPLE_CHOICE",
  checkBox: "CHECK_BOX",
  dropDown: "DROPDOWN",
  table: "TABLE",
  radioScale: "RADIO_SCALE",
  multipleShortAnswer: "MULTIPLE_SHORT_ANSWER",
};

export const BOOKING_STATUS = {
  available: {
    id: 1,
    name: "Available",
  },
  waitingPayment: {
    id: 2,
    name: "Waiting for Payment",
  },
  bookingConfirmed: {
    id: 3,
    name: "Booking Confirmed",
  },
  start: {
    id: 4,
    name: "Session Starts",
  },
  end: {
    id: 5,
    name: "Session Ends",
  },
  expired: {
    id: 6,
    name: "Expired",
  },
};

export const INPUT_TYPE_SHORT_ANSWER = "SHORT_ANSWER";
export const INPUT_TYPE_MULTIPLE_CHOICE = "MULTIPLE_CHOICE";
export const INPUT_TYPE_CHECK_BOX = "CHECK_BOX";
export const INPUT_TYPE_RADIO = "RADIO";
export const INPUT_TYPE_RADIO_SCALE = "RADIO_SCALE";
export const INPUT_TYPE_DROPDOWN = "DROPDOWN";
export const INPUT_TYPE_PARAGRAPH = "PARAGRAPH";
export const INPUT_TYPE_TABLE = "TABLE";
export const INPUT_TYPE_MULTIPLE_SHORT_ANSWER = "MULTIPLE_SHORT_ANSWER";

export const INPUT_TYPE_OPTION_LABELS: DefaultMap = {
  [INPUT_TYPE_SHORT_ANSWER]: "Text",
  [INPUT_TYPE_MULTIPLE_SHORT_ANSWER]: "Multiple Short Answer",
  [INPUT_TYPE_MULTIPLE_CHOICE]: "Multiple Choices",
  [INPUT_TYPE_CHECK_BOX]: "Checkboxes",
  [INPUT_TYPE_DROPDOWN]: "Dropdown",
  [INPUT_TYPE_TABLE]: "Table",
  [INPUT_TYPE_PARAGRAPH]: "Paragraph",
  [INPUT_TYPE_RADIO]: "Radio",
  [INPUT_TYPE_RADIO_SCALE]: "Radio Scale",
};

export const INPUT_TYPE_OPTIONS = [
  INPUT_TYPE_SHORT_ANSWER,
  INPUT_TYPE_MULTIPLE_CHOICE,
  INPUT_TYPE_CHECK_BOX,
  INPUT_TYPE_DROPDOWN,
  INPUT_TYPE_TABLE,
  INPUT_TYPE_PARAGRAPH,
  INPUT_TYPE_MULTIPLE_SHORT_ANSWER,
  INPUT_TYPE_RADIO_SCALE,
];

export const IMAGE_RECOMMENDATION_SIZE = {
  informationalPage: {
    home: {
      banner: "1366 x 668",
      bannerMobile: "428 x 648",
      aboutUs: "484 x 372",
      addClients: "200 x 200",
      ourOffice: "520 x 520, 300 x 300",
      merchandise: "220 x 220",
    },
    about: {
      aboutUs: "598 x 460",
      ourVision: "160 x 160",
      ourMission: "160 x 160",
      ourCampaign: "160 x 160",
      why: "80 x 80",
    },
    termAndConditions: "1088 x 450",
    privacyPolicy: "1088 x 450",
  },
  service: {
    ourService: "661 x 563",
    typeOfService: "28 x 28",
    areaOfExpertise: "28 x 28",
  },
  psychologist: "444 x 444",
  event: "735 x 400",
  insightAndResearch: {
    article: {
      article: "1087 x 450",
      author: "168 x 168",
    },
    research: "329 x 205",
    tags: "34 x 34",
  },
  social: "373 x 270",
};

export const ACCESS_PERMISSION = {
  user: {
    index: "admin.user.index",
    store: "admin.user.store",
    show: "admin.user.show",
    update: "admin.user.update",
    destroy: "admin.user.destroy",
  },
  role: {
    index: "admin.role.index",
    store: "admin.role.store",
    show: "admin.role.show",
    update: "admin.role.update",
    destroy: "admin.role.destroy",
  },
  page: {
    index: "admin.page.index",
    show: "admin.page.show",
    home: "admin.page.home",
    about: "admin.page.about",
    booking: "admin.page.booking",
    psychologist: "admin.page.psychologist",
    service: "admin.page.service",
    event: "admin.page.event",
    insight: "admin.page.insight",
    contact: "admin.page.contact",
    termConditions: "admin.page.terms-condition",
    privacyPolicy: "admin.page.privacy-policy",
    frequentlyAskedQuestion: "admin.page.frequently-asked-question",
  },
  service: {
    index: "admin.service.index",
    store: "admin.service.store",
    show: "admin.service.show",
    update: "admin.service.update",
    destroy: "admin.service.destroy",
  },
  schedule: {
    index: "admin.schedule.index",
    store: "admin.schedule.store",
    show: "admin.schedule.show",
    update: "admin.schedule.update",
    destroy: "admin.schedule.destroy",
  },
  psychologist: {
    index: "admin.psychologist.index",
    store: "admin.psychologist.store",
    show: "admin.psychologist.show",
    update: "admin.psychologist.update",
    destroy: "admin.psychologist.destroy",
  },
  expertise: {
    index: "admin.expertise.index",
    store: "admin.expertise.store",
    show: "admin.expertise.show",
    update: "admin.expertise.update",
    destroy: "admin.expertise.destroy",
  },
  category: {
    index: "admin.category.index",
    store: "admin.category.store",
    show: "admin.category.show",
    update: "admin.category.update",
    destroy: "admin.category.destroy",
  },
  article: {
    index: "admin.article.index",
    store: "admin.article.store",
    show: "admin.article.show",
    update: "admin.article.update",
    destroy: "admin.article.destroy",
  },
  research: {
    index: "admin.research.index",
    store: "admin.research.store",
    show: "admin.research.show",
    update: "admin.research.update",
    destroy: "admin.research.destroy",
  },
  tag: {
    index: "admin.tag.index",
    store: "admin.tag.store",
    show: "admin.tag.show",
    update: "admin.tag.update",
    destroy: "admin.tag.destroy",
  },
  event: {
    index: "admin.event.index",
    store: "admin.event.store",
    show: "admin.event.show",
    update: "admin.event.update",
    destroy: "admin.event.destroy",
  },
  subscribe: {
    index: "admin.subscribe.index",
    show: "admin.subscribe.show",
    destroy: "admin.subscribe.destroy",
  },
  contactForm: {
    index: "admin.contact-form.index",
    show: "admin.contact-form.show",
    destroy: "admin.contact-form.destroy",
  },
  setting: {
    show: "admin.setting.show",
    update: "admin.setting.update",
  },
  customer: {
    index: "admin.customer.index",
    store: "admin.customer.store",
    show: "admin.customer.show",
    update: "admin.customer.update",
    destroy: "admin.customer.destroy",
  },
  form: {
    index: "admin.form.index",
    store: "admin.form.store",
    show: "admin.form.show",
    update: "admin.form.update",
    destroy: "admin.form.destroy",
  },
  report: {
    index: "admin.report.index",
    show: "admin.report.show",
    excel: "admin.report.excel",
  },
  package: {
    show: "admin.package.show",
    update: "admin.package.update",
  },
  assessment: {
    index: "admin.assessment.index",
    store: "admin.assessment.store",
    show: "admin.assessment.show",
    update: "admin.assessment.update",
    destroy: "admin.assessment.destroy",
  },
};
