import { DeleteOutlined } from "@ant-design/icons/lib/icons";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { useState } from "@hookstate/core";
import { Switch } from "antd";
import Button from "antd/es/button";
import Input from "antd/es/input";
import PageHeader from "antd/es/page-header";
import Card from "antd/lib/card";
import Form from "antd/lib/form";
import { Col, Row } from "antd/lib/grid";
import Space from "antd/lib/space";
import Spin from "antd/lib/spin";
import Typography from "antd/lib/typography";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Accessible from "../../../../../@vodea/vodea-ui/components/Accessible";
import VuiContainer from "../../../../../@vodea/vodea-ui/components/Container";
import VuiEditor, {
  vuiEditorBaseInit,
} from "../../../../../@vodea/vodea-ui/components/Editor";
import VuiUploadImages from "../../../../../@vodea/vodea-ui/components/UploadImages";
import {
  ACCESS_PERMISSION,
  env,
  IMAGE_RECOMMENDATION_SIZE,
} from "../../../../../constant";
import {
  handleBackendError,
  openNotification,
  toFileList,
} from "../../../../../functions/global";
import PageRepository from "../../../../../repositories/PageRepository";

const { TextArea } = Input;
const { Text } = Typography;

let title = "Informational Frequently Asked Question Page";

const AppPageFrequentlyAskedQuestionForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);

  const onFinish = async (data: any) => {
    loading.set(true);

    const formData = {
      ...data,
      questions: data?.questions?.map((item: any) => {
        return {
          ...item,
          image: _.get(item, "image[0].url"),
          image_mobile: _.get(item, "image_mobile[0].url"),
        };
      }),
    };

    PageRepository.createFrequentlyAskedQuestion(formData)
      .then((res: AxiosResponse) => {
        navigate(-1);
        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    await PageRepository.show(id)
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};

        if (!_.isEmpty(data)) {
          const modified: any = {
            ...data,
          };

          Object.keys(modified).forEach((key) => {
           // if (
           //    key === "questions"
           //  ) {
           //    modified[key] = data[key].map((item: any) => {
           //      const obj: any = {
           //        ...item,
           //        image: toFileList({ url: item.image }),
           //      };
           //      if (item.image_mobile) {
           //        obj["image_mobile"] = toFileList({ url: item.image_mobile });
           //      }
           //      return obj;
           //    });
           //  }
          });

          form.setFieldsValue({
            ...modified,
          });
        }
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  };

  return (
    <>
      <Helmet>
        <title>{title} Detail</title>
      </Helmet>
      <VuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          initialValues={{
            questions: [
              {
                title: "",
                description: "",
                image: [],
                image_mobile: [],
                can_click: false,
                link: "",
              },
            ],
          }}
        >
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
              }}
              xs={{
                span: 24,
              }}
            >
              <Card title={""} className="mb16">
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={0}
                >
                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="meta_title"
                        label={"Page Title (SEO)"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Page Title (SEO)",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Page Title (SEO)",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="meta_description"
                        label={"Page Description (SEO)"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Page Description (SEO)",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Page Description (SEO)",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="meta_keyword"
                        label={"Keywords (SEO)"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Keywords (SEO)",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Keywords (SEO)",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                    size={15}
                  >
                    <Form.List name="questions">
                      {(fields, { add, remove }) => (
                        <Card
                          title={"Add Questions"}
                          extra={
                            <Button
                              type="primary"
                              onClick={() => add()}
                              icon={<PlusOutlined />}
                            >
                              Add
                            </Button>
                          }
                        >
                          <Space
                            style={{
                              width: "100%",
                            }}
                            direction="vertical"
                            size={15}
                          >
                            {fields.map(({ key, name, ...restField }) => (
                              <Card
                                title={`Question ${name + 1}`}
                                key={key}
                                extra={
                                  <>
                                    {name !== 0 ? (
                                      <DeleteOutlined
                                        onClick={() => remove(name)}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </>
                                }
                              >
                                <div className="vui-form-group type-column">
                                  <div className="input-section">
                                    <Form.Item
                                        {...restField}
                                        name={[name, "question"]}
                                        label={"Question"}
                                        rules={[
                                          {
                                            required: true,
                                            message: t("validation.required", {
                                              item: "Question",
                                            }),
                                          },
                                        ]}
                                    >
                                      <Input
                                          size={"large"}
                                          placeholder={t("common.text.input", {
                                            item: "Question",
                                          })}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="vui-form-group type-column">
                                  <div className="input-section">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "answer"]}
                                      label={"Answer"}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("validation.required", {
                                            item: "Answer",
                                          }),
                                        },
                                      ]}
                                    >
                                      <VuiEditor
                                        disabled={disable.get()}
                                        apiKey={env.getTinyApiKey()}
                                        init={{
                                          ...vuiEditorBaseInit,
                                          placeholder: t("common.text.input", {
                                            item: "Answer",
                                          }),
                                        }}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                              </Card>
                            ))}
                          </Space>
                        </Card>
                      )}
                    </Form.List>
                  </Space>
                </Space>
              </Card>
            </Col>
          </Row>

          <div className="vui-form-btn-group">
            <Button
              className="vui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Accessible access={ACCESS_PERMISSION.page.frequentlyAskedQuestion}>
              <Spin spinning={loading.get()}>
                <Button
                  className="vui-btn"
                  type="primary"
                  htmlType="submit"
                  size={"large"}
                >
                  Submit
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </VuiContainer>
    </>
  );
};

export default AppPageFrequentlyAskedQuestionForm;
