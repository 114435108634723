import notification, { IconType } from "antd/lib/notification";
import { SortOrder } from "antd/lib/table/interface";
import { AxiosError } from "axios";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { IAnswerTable } from "../components/CounselingForm";
import { QUESTION_FORM_TYPE } from "../constant";

export const openNotification = (
  type: IconType,
  message: string,
  description?: string
) => {
  notification[type]({
    message: message,
    description: description,
    placement: "top",
  });
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const ordinalSuffixOf = (value: any) => {
  if (!Number(value)) {
    return "";
  }

  const j = parseFloat(value) % 10;
  const k = parseFloat(value) % 100;

  if (j === 1 && k !== 11) {
    return value + "st";
  }

  if (j === 2 && k !== 12) {
    return value + "nd";
  }

  if (j === 3 && k !== 13) {
    return value + "rd";
  }

  return value + "th";
};

export const mapHookErrors = (errors: any) => {
  let newErrors: any = {};

  Object.keys(errors).forEach((key) => {
    Object.assign(newErrors, {
      [key]: {
        message: errors[key][0]
          .replace(/_id/g, "")
          .replace(/ id/g, "")
          .replace(/\.\d{1,3}\./g, (match: string) => {
            return ` ${match
              .replace(/\./g, "")
              .replace(/\d{1,3}/g, (a) =>
                ordinalSuffixOf(parseFloat(a) + 1)
              )} `;
          })
          .replace(/\w\.\w/g, (match: string) => match.replace(/\./g, " "))
          .replace(/_/g, " "),
        type: "manual",
      },
    });
  });

  return newErrors;
};

export const pluckArrayObject = (array: any, props: any) => {
  return _.map(array, (o) => _.pick(o, props));
};

export const renameObjectOfArrays = (array: any, keyMap: any) => {
  return array.map((obj: any) => {
    return _.mapKeys(obj, function (value, key) {
      return keyMap[key];
    });
  });
};

export const getAliasesName = (value: string) => {
  try {
    const arrayName = value.split(" ");
    let aliasesName = value.charAt(0) + value.charAt(1);

    if (arrayName.length >= 2) {
      aliasesName = arrayName[0].charAt(0) + arrayName[1].charAt(0);
    }

    return aliasesName.toUpperCase();
  } catch (err) {
    return "?";
  }
};

export const toFileList = (data: any[] | any) => {
  if (!data) return [];

  if (Array.isArray(data)) {
    return _.map(data, (o) => {
      return {
        id: o.id,
        name: o.name,
        status: "done",
        url: o.url,
      };
    });
  } else {
    return [
      {
        id: data?.id,
        name: data?.name,
        status: "done",
        url: data?.url,
      },
    ];
  }
};

export const handleBackendError = (e: AxiosError, msg: string = "") => {
  let err = e.response?.data;

  if (err) {
    Object.keys(err).forEach((key) => {
      let text = msg;
      if (!err[key] || err[key] === "Server Error") {
        text = msg;
      } else {
        text = err[key];
      }

      if (_.isObject(text)) {
        Object.keys(text).forEach((childKey) => {
          openNotification("error", _.get(text, `${childKey}`)[0]);
        });
      } else {
        if (typeof text === "string" && !!text) {
          openNotification("error", text);
        }
      }
    });
  } else {
    openNotification("error", msg);
  }
};

export const haveAccess = (
  acl: string[],
  access: string | string[]
): boolean => {
  if (access === "IGNORE") {
    return true;
  }
  if (typeof access === "string") {
    if (acl.includes(access)) {
      return true;
    } else {
      return false;
    }
  } else {
    let allow = false;
    access.forEach((item: any) => {
      if (acl.includes(item)) {
        allow = true;
      }
    });
    return allow;
  }
};

export const recentFilterData = {
  save: (key: string, val: any) =>
    localStorage.setItem(key, JSON.stringify(val)),
  get: (key: string) => JSON.parse(localStorage.getItem(key) || "[]"),
  delete: (key: string) => localStorage.removeItem(key),
  getSelectedFilter: (filterData: any, selectedFilter: any) => {
    return _.filter(filterData, (data) => {
      return selectedFilter.includes(data.id || data.value);
    });
  },
};

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const counselingForm = {
  toSave: (data: any) => {
    const counselingFormAnswers: any[] = [];

    Object.keys(data).forEach((key: string) => {
      const splitKey = key.split("-");
      if (splitKey.length === 2) {
        const questionId = splitKey[0];
        const questionType = splitKey[1];

        if (questionType === QUESTION_FORM_TYPE.multipleChoice) {
          if (data[key] === "other") {
            counselingFormAnswers.push({
              form_question_id: questionId,
              answers: data[`${key}-other`],
            });
          } else {
            counselingFormAnswers.push({
              form_question_id: questionId,
              answers: data[key],
            });
          }
        } else if (questionType === QUESTION_FORM_TYPE.checkBox) {
          if (data[key]?.includes("other")) {
            const removeOtherAnswer = data[key].filter(
              (item: string) => item !== "other"
            );
            removeOtherAnswer.push(data[`${key}-other`]);
            counselingFormAnswers.push({
              form_question_id: questionId,
              answers: removeOtherAnswer,
            });
          } else {
            counselingFormAnswers.push({
              form_question_id: questionId,
              answers: data[key],
            });
          }
        } else if (questionType === QUESTION_FORM_TYPE.table) {
          const answers: any[] = [];
          data[key].forEach((item: any) => {
            const rows: string[] = [];
            Object.keys(item).forEach((valKey: any) => {
              rows.push(item[valKey]);
            });
            answers.push(rows);
          });

          counselingFormAnswers.push({
            form_question_id: questionId,
            answers: answers,
          });
        } else {
          counselingFormAnswers.push({
            form_question_id: questionId,
            answers: data[key],
          });
        }
      }
    });
    return counselingFormAnswers;
  },
  toShow: (data: any) => {
    const questionAnswerData: any = {};

    data.forEach((item: any) => {
      const questionForm = item?.old_question || item?.form_question;
      const questionId = item?.old_question_id || item?.form_question_id;
      const questionType = questionForm?.type;
      const userAnswers = isJsonString(item.answers)
        ? JSON.parse(item.answers)
        : item.answers;
      const questionAnswers = questionForm.answers;
      const formName = `${questionId}-${questionType}`;
      if (questionId && questionType && userAnswers) {
        if (questionType === QUESTION_FORM_TYPE.multipleChoice) {
          if (questionAnswers.includes(userAnswers)) {
            questionAnswerData[formName] = userAnswers;
          } else {
            questionAnswerData[formName] = "other";
            questionAnswerData[`${formName}-other`] = userAnswers;
          }
        } else if (questionType === QUESTION_FORM_TYPE.checkBox) {
          let otherAnswer: string = "";
          if (typeof userAnswers === "string") {
            if (!questionAnswers.includes(userAnswers)) {
              otherAnswer = userAnswers;
            }
          } else {
            otherAnswer = userAnswers.find(
              (userAnswer: string) => !questionAnswers.includes(userAnswer)
            );
          }

          if (otherAnswer) {
            if (typeof userAnswers === "string") {
              questionAnswerData[formName] = ["other"];
              questionAnswerData[`${formName}-other`] = otherAnswer;
            } else {
              const answersClone = userAnswers.filter(
                (userAnswer: string) => userAnswer !== otherAnswer
              );
              answersClone.push("other");

              questionAnswerData[formName] = answersClone;
              questionAnswerData[`${formName}-other`] = otherAnswer;
            }
          } else {
            questionAnswerData[formName] =
              typeof userAnswers === "string" ? [userAnswers] : userAnswers;
          }
        } else if (questionType === QUESTION_FORM_TYPE.table) {
          const column: IAnswerTable =
            questionAnswers.find(
              (questionAnswer: any) => questionAnswer.type === "column"
            ) || ({} as IAnswerTable);
          questionAnswerData[formName] = userAnswers.map(
            (userAnswer: string[]) => {
              const cloneUserAnswer: any[] = [...userAnswer];
              if (userAnswer.length !== column.list.length) {
                cloneUserAnswer.unshift(null);
              }
              const obj: any = {};
              cloneUserAnswer.forEach((answer: string, i: number) => {
                obj[i] = answer;
              });
              return obj;
            }
          );
        } else {
          questionAnswerData[formName] = userAnswers;
        }
      }
    });

    return questionAnswerData;
  },
};

export const convertLabelInValue = (
  data: any,
  labelKey: string = "name",
  valueKey: string = "id"
) => {
  let result: any = null;

  if (data) {
    if (data.length > 0) {
      result = data.map((item: any) => ({
        value: item[valueKey],
        label: item[labelKey],
      }));
    } else {
      result = {
        value: data[valueKey],
        label: data[labelKey],
      };
    }
  }

  return result;
};

export const overideTableSortOrder = (orderBy: SortOrder) => {
  if (orderBy === "descend") {
    return "desc";
  }

  return orderBy;
};

export const getNoPhotoPlaceholder = () => {
  return {
    url: `https://placehold.co/400x400?text=No Photo`,
  };
};
