import Button from "antd/es/button";
import PageHeader from "antd/es/page-header";
import Card from "antd/lib/card";
import Form from "antd/lib/form";
import { Col, Row } from "antd/lib/grid";
import Space from "antd/lib/space";
import Typography from "antd/lib/typography";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import VuiContainer from "../../../../@vodea/vodea-ui/components/Container";
import { SCHEDULE_STATUS, QUESTION_FORM_TYPE, env } from "../../../../constant";
import {
  counselingForm as counselingFormHelper,
  getNoPhotoPlaceholder,
  handleBackendError,
  openNotification,
  toFileList,
} from "../../../../functions/global";
import { useReactToPrint } from "react-to-print";
import CounselingForm, {
  CounselingFormCheckBox,
  CounselingFormDropDown,
  CounselingFormMultipleChoice,
  CounselingFormMultipleShortAnswer,
  CounselingFormParagraph,
  CounselingFormRadioScale,
  CounselingFormShortAnswer,
  CounselingFormTable,
  IFormQuestion,
} from "../../../../components/CounselingForm";
import BookingRepository from "../../../../repositories/BookingRepository";
import CustomFormRepository from "../../../../repositories/CustomFormRepository";
import { Resource } from "../../../../entities/base/resource";
import FormItemView from "../../../../@vodea/vodea-ui/components/Form/FormItemView";
import StatusTag from "../../../../components/StatusTag";
import FormAction from "../../../../components/FormAction";
import SessionModal from "./SessionModal";
import { Booking } from "../../../../entities/booking";
import { Spin, Tabs } from "antd";
import VuiUpload from "../../../../@vodea/vodea-ui/components/Upload";
import { Media } from "../../../../entities/media";
import VuiModalConfirmation from "../../../../@vodea/vodea-ui/components/Modal/Confirmation";
import VuiEditor, {
  vuiEditorBaseInit,
} from "../../../../@vodea/vodea-ui/components/Editor";

const { Title } = Typography;
const { TabPane } = Tabs;

let title = "Booking";

const AppBookingView: React.FC<any> = () => {
  const componentToPrintRef = React.useRef(null);
  const internalFormToPrintRef = React.useRef(null);
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = t("common.text.detailItem", { item: title });

  const [booking, setBooking] = React.useState<Booking>();
  const [isSavingNotes, setIsSavingNotes] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  // counseling form
  const [counselingFormInstance] = Form.useForm();
  const [counselingForm, setCounselingForm] = React.useState<any>(null);
  const [internalForm, setInternalForm] = React.useState<any>();
  const [isFetchingData, setIsFetchingData] = React.useState(false);
  const [showAllSchedule, setShowAllSchedule] = useState(false);

  // internal form
  const [internalFormInstance] = Form.useForm();

  //Confirm Modal
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  const {
    creator,
    number,
    date: booking_date,
    invoice,
    category,
    gmeet_link,
    assessment_id,
    assessment_name,
    sessions,
    schedule,
  } = booking || {};

  const { date, session_type, psychologist, type, time_from, time_to } =
    schedule || {};

  const status = useMemo(() => {
    return schedule?.status ?? booking?.booking_status;
  }, [booking?.booking_status, schedule?.status]);

  const isNoSchedule = useMemo(() => {
    return !schedule?.id;
  }, [schedule?.id]);

  const getCurrentSession = useCallback(() => {
    if (sessions?.length) {
      const current = sessions?.findIndex((item) => item.date === date);

      if (current !== -1) {
        return `Session ${current + 1}`;
      }
    }

    return null;
  }, [date, sessions]);

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    setIsFetchingData(true);
    const payload = {
      with: [
        "schedule",
        "schedule.psychologist",
        "schedule.status",
        "category",
        "customer.photo",
        "counselingForm.bookingFormAnswers.formQuestion",
        "counselingForm.form",
        "bookingForms.bookingFormAnswers.formQuestion",
        "bookingForms.form",
        "invoice",
        "creator",
        "bookingStatus",
        "reports.file",
      ],
    };
    await BookingRepository.show(id, payload)
      .then((res: AxiosResponse<Resource<Booking>>) => {
        const data = res.data?.data || {};

        let formTypeInternalCounseling = "Internal";
        let formTypeInternalAssessment = "Internal Assessment";

        let questionAnswerInternal: any = {};

        const counselingFormData = data?.counseling_form;
        const internalFormData = data?.booking_forms?.find(
          (item: any) =>
            item.type === formTypeInternalCounseling ||
            item.type === formTypeInternalAssessment,
        );

        if (counselingFormData) {
          const formQuestion = counselingFormData.booking_form_answers?.map(
            (item: any) => {
              return item?.old_question || item?.form_question;
            },
          );
          setCounselingForm({
            id: counselingFormData.form_id,
            form_questions: formQuestion,
            name: counselingFormData?.form.name,
            description: counselingFormData?.form.description,
          });

          const patientQuestionAnswer = counselingFormHelper.toShow(
            counselingFormData.booking_form_answers,
          );

          counselingFormInstance.setFieldsValue(patientQuestionAnswer);
        }

        if (internalFormData) {
          const internalformQuestions =
            internalFormData.booking_form_answers?.map((item) => {
              return item?.old_question || item?.form_question;
            });

          setInternalForm({
            id: internalFormData.form_id,
            form_questions: internalformQuestions,
            name: internalFormData?.form.name,
            description: internalFormData?.form.description,
            can_download: true,
          });
          questionAnswerInternal = counselingFormHelper.toShow(
            internalFormData.booking_form_answers,
          );
          internalFormInstance.setFieldsValue(questionAnswerInternal);
        } else {
          const statusData = data?.schedule?.status ?? data?.booking_status;
          if (statusData?.id !== SCHEDULE_STATUS.notStarted.id) {
            getFormData(data.schedule?.session_type ?? "Assessment");
          }
        }

        form.setFieldsValue({
          ...questionAnswerInternal,
          notes: data?.notes,
          file: toFileList(data?.file),
          reports: toFileList(
            data?.reports?.map((report) => report?.file) ?? [],
          ),
        });

        setBooking(data);
        setIsFetchingData(false);
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        setIsFetchingData(false);
      });
  };

  const getFormData = useCallback(
    async (sessionType) => {
      if (!sessionType) return;

      if (sessionType === "COUNSELING") {
        await CustomFormRepository.internalCounseling()
          .then((res: AxiosResponse) => {
            const data = res.data?.data || {};
            setInternalForm(data);
          })
          .catch((e: AxiosError) => {
            handleBackendError(e, t("notification.error.default"));
          });
      }

      if (sessionType === "ASSESSMENT" || isNoSchedule) {
        await CustomFormRepository.internalAssessment()
          .then((res: AxiosResponse) => {
            const data = res.data?.data || {};
            setInternalForm(data);
          })
          .catch((e: AxiosError) => {
            handleBackendError(e, t("notification.error.default"));
          });
      }
    },
    [isNoSchedule, t],
  );

  const onFinish = async (data: any) => {
    setIsSavingNotes(true);

    const formData: any = {
      notes: data.notes,
      file_id: _.get(data, "file[0].id"),
      booking_forms: [
        {
          form_id: internalForm?.id,
          booking_forms_answers: counselingFormHelper.toSave(data),
        },
      ],
    };

    const reports = _.get(data, "reports");
    if (reports !== undefined) {
      Object.assign(formData, {
        report_ids: reports.map((item: Media) => item.id),
      });
    }

    await BookingRepository.saveNotes(id, formData)
      .then((res: AxiosResponse) => {
        openNotification(
          "success",
          t("notification.success.updateItem", { item: "Notes" }),
        );
        setIsSavingNotes(false);
        getData();
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        setIsSavingNotes(false);
      });
  };

  const startSession = async () => {
    setIsLoading(true);

    await BookingRepository.startSession(id)
      .then((res: AxiosResponse) => {
        openNotification("success", "successfully start this session");
        setShowConfirmModal(false);
        getData();
        setIsLoading(false);
      })
      .catch((e: AxiosError) => {
        setIsLoading(false);
        handleBackendError(e, t("notification.error.default"));
      });
  };

  const handleEndSession = async () => {
    await form
      .validateFields()
      .then(async () => {
        await BookingRepository.endSession(id)
          .then(async (res: AxiosResponse) => {
            await form.submit();
            openNotification("success", "successfully completed this session");
            setShowConfirmModal(false);
          })
          .catch((e: AxiosError) => {
            handleBackendError(e, t("notification.error.default"));
          });
      })
      .catch(() => {
        openNotification("error", t("notification.error.uncompleteForm"));
      });
  };

  const reactToPrintContent = React.useCallback(() => {
    return componentToPrintRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentToPrintRef.current, counselingForm]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Counseling Form",
    removeAfterPrint: true,
  });

  //handle internal form print
  const internalFormPrintContent = React.useCallback(() => {
    return internalFormToPrintRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalFormToPrintRef.current]);

  const handleInternalFormPrint = useReactToPrint({
    content: internalFormPrintContent,
    documentTitle: "Internal Form",
    removeAfterPrint: true,
  });
  //end handle internal form print

  const renderCounselingForm = () => {
    return counselingForm.form_questions.map(
      (question: IFormQuestion, index: number) => {
        switch (question?.type) {
          case QUESTION_FORM_TYPE.shortAnswer:
            return (
              <Card key={index} className="mb16 form-card">
                <div className="vui-form-group type-column">
                  <div className="input-section">
                    <CounselingFormShortAnswer
                      question={question}
                      disabled={false}
                    />
                  </div>
                </div>
              </Card>
            );
          case QUESTION_FORM_TYPE.paragraph:
            return (
              <Card key={index} className="mb16 form-card">
                <div className="vui-form-group type-column">
                  <div className="input-section">
                    <CounselingFormParagraph
                      question={question}
                      disabled={false}
                      forPrint
                    />
                  </div>
                </div>
              </Card>
            );
          case QUESTION_FORM_TYPE.multipleChoice:
            return (
              <Card key={index} className="mb16 form-card">
                <div className="vui-form-group type-column">
                  <div className="input-section">
                    <CounselingFormMultipleChoice
                      question={question}
                      disabled={false}
                    />
                  </div>
                </div>
              </Card>
            );
          case QUESTION_FORM_TYPE.checkBox:
            return (
              <Card key={index} className="mb16 form-card">
                <div className="vui-form-group type-column">
                  <div className="input-section">
                    <CounselingFormCheckBox
                      question={question}
                      disabled={false}
                    />
                  </div>
                </div>
              </Card>
            );

          case QUESTION_FORM_TYPE.dropDown:
            return (
              <Card key={index} className="mb16 form-card">
                <div className="vui-form-group type-column">
                  <div className="input-section">
                    <CounselingFormDropDown
                      question={question}
                      disabled={false}
                    />
                  </div>
                </div>
              </Card>
            );
          case QUESTION_FORM_TYPE.radioScale:
            return (
              <Card key={index} className="mb16 form-card">
                <div className="vui-form-group type-column">
                  <div className="input-section">
                    <CounselingFormRadioScale
                      question={question}
                      disabled={false}
                    />
                  </div>
                </div>
              </Card>
            );
          case QUESTION_FORM_TYPE.multipleShortAnswer:
            return (
              <Card key={index} className="mb16 form-card">
                <div className="vui-form-group type-column">
                  <div className="input-section">
                    <CounselingFormMultipleShortAnswer
                      question={question}
                      disabled={false}
                    />
                  </div>
                </div>
              </Card>
            );

          case QUESTION_FORM_TYPE.table:
            return (
              <Card key={index} className="mb16 form-card">
                <div className="mb24 vui-form-group type-column">
                  <div className="input-section">
                    <CounselingFormTable question={question} disabled={false} />
                  </div>
                </div>
              </Card>
            );

          default:
            return <div key={index} />;
        }
      },
    );
  };

  const renderExternal = () => {
    return (
      <div className="vui-form-group type-column">
        <div className="input-section">
          <Form.Item
            name="notes"
            rules={[
              {
                required: true,
                message: t("validation.required", {
                  item: "Notes",
                }),
              },
            ]}
          >
            <VuiEditor
              disabled={!isNoSchedule}
              apiKey={env.getTinyApiKey()}
              init={{
                ...vuiEditorBaseInit,
                placeholder: t("common.text.input", {
                  item: "Notes",
                }),
              }}
            />
          </Form.Item>
        </div>

        {(session_type === "ASSESSMENT" || isNoSchedule) && (
          <Form.Item label="Report" name="reports">
            <VuiUpload isUploadFirst max={10} disabled={!isNoSchedule} />
          </Form.Item>
        )}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title} Detail</title>
      </Helmet>
      <VuiContainer bottomSpace={110}>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
          tags={
            !!status ? (
              <StatusTag
                title={
                  booking?.is_expired ? "Expired" : _.get(status, "label", "")
                }
              />
            ) : undefined
          }
          extra={
            !!internalForm &&
            isNoSchedule &&
            status?.id === SCHEDULE_STATUS.done.id && (
              <Button onClick={handleInternalFormPrint} key="2" type="primary">
                Download Internal Form
              </Button>
            )
          }
        />

        <Form
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          onFinishFailed={() =>
            openNotification("error", t("notification.error.uncompleteForm"))
          }
        >
          <Row gutter={[20, 20]}>
            <Col xs={24} lg={8}>
              <Space className="flex" direction="vertical" size={20}>
                <Card loading={isFetchingData}>
                  <Space direction="vertical" size={16}>
                    <FormItemView
                      label="Account"
                      value={
                        <Link
                          to={`/customer/${_.get(creator, "id")}`}
                          target="_blank"
                        >
                          <u>{_.get(creator, "name")}</u>
                        </Link>
                      }
                    />
                  </Space>
                </Card>
                <Card loading={isFetchingData}>
                  <Space direction="vertical" size={16}>
                    {!isNoSchedule && (
                      <FormItemView
                        label="Psychologist"
                        value={_.get(psychologist, "name")}
                      />
                    )}

                    <FormItemView
                      label="Type"
                      value={_.capitalize(
                        isNoSchedule ? "Assessment" : session_type,
                      )}
                    />

                    {session_type === "COUNSELING" ? (
                      <FormItemView
                        label="Category"
                        value={_.get(category, "name")}
                      />
                    ) : (
                      <FormItemView
                        label="Assessment Name"
                        value={
                          assessment_name ? (
                            <Link
                              to={`/assessment/${assessment_id}`}
                              target="_blank"
                            >
                              <u>{assessment_name}</u>
                            </Link>
                          ) : (
                            "-"
                          )
                        }
                      />
                    )}
                    {!isNoSchedule && (
                      <FormItemView label="Method" value={_.capitalize(type)} />
                    )}
                    {!!getCurrentSession() && (
                      <FormItemView
                        label="Session"
                        value={getCurrentSession()}
                      />
                    )}

                    {!!getCurrentSession() && (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a role="button" onClick={() => setShowAllSchedule(true)}>
                        <u>All Session</u>
                      </a>
                    )}

                    {!isNoSchedule && (
                      <FormItemView
                        label="Date | Time"
                        value={
                          <Link
                            to={`/schedule/detail/${schedule?.id}`}
                            target="_blank"
                          >
                            <u>
                              {`${moment(date).format(
                                "DD MMM YYYY",
                              )} | ${time_from} - ${time_to}`}
                            </u>
                          </Link>
                        }
                      />
                    )}

                    {session_type === "COUNSELING" && (
                      <FormItemView
                        label="Counseling Form"
                        value={
                          counselingForm ? (
                            <Button
                              type="primary"
                              size={"large"}
                              onClick={handlePrint}
                            >
                              Download Conseling Form
                            </Button>
                          ) : (
                            "Not Filled"
                          )
                        }
                      />
                    )}
                    {!!gmeet_link && (
                      <FormItemView
                        label="Google Meet Link"
                        value={
                          gmeet_link ? (
                            <a
                              href={gmeet_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <u>{gmeet_link}</u>
                            </a>
                          ) : (
                            "-"
                          )
                        }
                      />
                    )}
                  </Space>
                </Card>
              </Space>
            </Col>
            <Col xs={24} lg={16}>
              <Space className="flex" direction="vertical" size={20}>
                <Card loading={isFetchingData}>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} lg={8}>
                      <FormItemView
                        label="Booking Date"
                        value={moment(booking_date).format("DD MMM YYYY")}
                      />
                    </Col>
                    <Col xs={24} lg={8}>
                      <FormItemView label="Booking Number" value={number} />
                    </Col>
                    <Col xs={24} lg={8}>
                      <FormItemView
                        label="Invoice Number"
                        value={_.get(invoice, "number")}
                      />
                    </Col>
                  </Row>
                </Card>
                <Card
                  title={
                    <Typography.Title level={5}>
                      Patient Information
                    </Typography.Title>
                  }
                  loading={isFetchingData}
                >
                  <Space className="flex" direction="vertical" size={20}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} lg={8}>
                        <FormItemView
                          label="Patient Photo"
                          value={
                            _.get(booking, "customer.photo") ??
                            getNoPhotoPlaceholder()
                          }
                          type="file"
                        />
                      </Col>
                      <Col xs={24} lg={16}>
                        <Row gutter={[16, 30]}>
                          <Col xs={24} lg={12}>
                            <FormItemView
                              label="Patient Name"
                              value={
                                <Link
                                  to={`/customer/${_.get(
                                    booking,
                                    "customer.id",
                                  )}`}
                                  target="_blank"
                                >
                                  <u>{_.get(booking, "customer.name")}</u>
                                </Link>
                              }
                            />
                          </Col>
                          <Col xs={24} lg={12}>
                            <FormItemView
                              label="Email"
                              value={_.get(booking, "customer.email")}
                            />
                          </Col>
                          <Col xs={24} lg={12}>
                            <FormItemView
                              label="Phone Number"
                              value={_.get(booking, "customer.phone_number")}
                            />
                          </Col>
                          <Col xs={24} lg={12}>
                            <FormItemView
                              label="Date of Birth"
                              value={moment(
                                _.get(booking, "customer.date_of_birth"),
                              ).format("DD MMM YYYY")}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} lg={8}>
                        <FormItemView
                          label="Gender"
                          value={_.get(booking, "customer.gender")}
                        />
                      </Col>
                      <Col xs={24} lg={16}>
                        <FormItemView
                          label="Address"
                          value={_.get(booking, "customer.address")}
                        />
                      </Col>
                    </Row>
                  </Space>
                </Card>
              </Space>
            </Col>

            <Col xs={24} lg={24}>
              <Space className="flex" direction="vertical" size={20}>
                {status?.id !== SCHEDULE_STATUS.notStarted.id && (
                  <Card
                    loading={isFetchingData}
                    className="schedule-patient-section"
                  >
                    <Tabs defaultActiveKey="online">
                      <TabPane forceRender tab="External" key="external">
                        {renderExternal()}
                      </TabPane>
                      <TabPane forceRender tab="Internal" key="internal">
                        <CounselingForm
                          formData={internalForm?.form_questions || []}
                          disabled={!isNoSchedule}
                        />
                        {session_type === "COUNSELING" && (
                          <Form.Item label="File" name="file">
                            <VuiUpload disabled={!isNoSchedule} isUploadFirst />
                          </Form.Item>
                        )}
                      </TabPane>
                    </Tabs>
                  </Card>
                )}
              </Space>
            </Col>
          </Row>

          {!isFetchingData && (
            <>
              {isNoSchedule ? (
                <FormAction
                  formName={title}
                  onCancel={() => navigate(-1)}
                  onSubmitting={isSavingNotes}
                  submitAccess={"IGNORE"}
                  submitTitle="Save"
                  showSubmit={status?.id !== SCHEDULE_STATUS.notStarted.id}
                  suffix={
                    status?.id !== SCHEDULE_STATUS.done.id && (
                      <Spin spinning={isLoading || isSavingNotes}>
                        <Button
                          className="vui-btn"
                          type="primary"
                          htmlType="button"
                          onClick={() => {
                            if (status?.id === SCHEDULE_STATUS.notStarted.id) {
                              startSession();
                            } else {
                              setShowConfirmModal(true);
                            }
                          }}
                        >
                          {status?.id === SCHEDULE_STATUS.notStarted.id
                            ? "Start Session"
                            : "Save & End Session"}
                        </Button>
                      </Spin>
                    )
                  }
                />
              ) : (
                <FormAction formName={title} onCancel={() => navigate(-1)} />
              )}
            </>
          )}
        </Form>

        {Boolean(counselingForm) && (
          <div style={{ display: "none" }}>
            <div ref={componentToPrintRef} id="counseling-form">
              <style type="text/css" media="print">
                {
                  "\
                  @page { size: potrait;}\
                "
                }
              </style>
              <div className="counseling-form-container">
                <img
                  className="logo mb24"
                  src={"/images/logo.png"}
                  alt="logo"
                />
                <Title className="page-title mb24" level={3}>
                  {counselingForm.name}
                </Title>
                <div
                  className="page-subtitle"
                  dangerouslySetInnerHTML={{
                    __html: counselingForm.description,
                  }}
                />
                <div className="mb24" />
                <Form
                  form={counselingFormInstance}
                  layout={"vertical"}
                  onFinishFailed={() =>
                    openNotification(
                      "error",
                      t("notification.error.uncompleteForm"),
                    )
                  }
                >
                  {renderCounselingForm()}
                </Form>
              </div>
            </div>
          </div>
        )}

        {!!internalForm && (
          <div style={{ display: "none" }}>
            <div ref={internalFormToPrintRef} id="counseling-form">
              <style type="text/css" media="print">
                {
                  "\
                    @page { size: potrait;}\
                  "
                }
              </style>
              <div className="counseling-form-container">
                <img
                  className="logo mb24"
                  src={"/images/logo.png"}
                  alt="logo"
                />
                <Title className="page-title mb24" level={3}>
                  {internalForm.name}
                </Title>
                <div
                  className="page-subtitle"
                  dangerouslySetInnerHTML={{
                    __html: internalForm.description,
                  }}
                />
                <div className="mb24" />
                <Form form={internalFormInstance} layout={"vertical"}>
                  <CounselingForm
                    formData={internalForm.form_questions}
                    disabled={true}
                    forPrint
                  />
                </Form>
              </div>
            </div>
          </div>
        )}
      </VuiContainer>

      <SessionModal
        visible={showAllSchedule}
        onClose={() => setShowAllSchedule(false)}
        sessions={sessions}
      />

      <VuiModalConfirmation
        show={showConfirmModal}
        onOk={handleEndSession}
        onCancel={() => setShowConfirmModal(false)}
        dangerSubmit={false}
        submitBtnTitle={"End Session"}
        headerTitle={t("psychologistSchedule.modal.endSession.headerTitle")}
        title={t("psychologistSchedule.modal.endSession.title")}
        subtitle={t("psychologistSchedule.modal.endSession.subtitle")}
      />
    </>
  );
};

export default AppBookingView;
