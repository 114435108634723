import { DeleteOutlined } from "@ant-design/icons/lib/icons";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import Input from "antd/es/input";
import PageHeader from "antd/es/page-header";
import Card from "antd/lib/card";
import Form from "antd/lib/form";
import { Col, Row } from "antd/lib/grid";
import Space from "antd/lib/space";
import Spin from "antd/lib/spin";
import Title from "antd/lib/typography/Title";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Accessible from "../../../../@vodea/vodea-ui/components/Accessible";
import VuiContainer from "../../../../@vodea/vodea-ui/components/Container";
import VuiEditor, {
  vuiEditorBaseInit,
} from "../../../../@vodea/vodea-ui/components/Editor";
import VuiSelectMulti from "../../../../@vodea/vodea-ui/components/Select/Multi";
import VuiUploadImages from "../../../../@vodea/vodea-ui/components/UploadImages";
import {
  ACCESS_PERMISSION,
  env,
  IMAGE_RECOMMENDATION_SIZE,
} from "../../../../constant";
import {
  handleBackendError,
  openNotification,
  toFileList,
} from "../../../../functions/global";
import PsychologistRepository from "../../../../repositories/PsychologistRepository";
import ServiceRepository from "../../../../repositories/ServiceRepository";
import ReactQuill from "react-quill";

const { TextArea } = Input;

let title = "Service";

const AppServiceForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);

  const onFinish = async (data: any) => {
    loading.set(true);

    const formData = {
      ...data,
      expertise_ids: _.map(data.expertise_ids, "id"),
      category_ids: _.map(data.category_ids, "id"),
      photo_id: _.get(data, "photo_id[0].id"),
      types: data?.types?.map((type: any) => {
        return {
          ...type,
          icon_id: _.get(type, "icon_id[0].id"),
          type_expertises: type?.type_expertises?.map((type_expertise: any) => {
            return {
              ...type_expertise,
              icon_id: _.get(type_expertise, "icon_id[0].id"),
              psychologist_ids: _.map(type_expertise.psychologist_ids, "id"),
            };
          }),
        };
      }),
    };

    await (
      !id
        ? ServiceRepository.create(formData)
        : ServiceRepository.update(id, formData)
    )
      .then((res: AxiosResponse) => {
        navigate(-1);
        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title }),
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title }),
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    const payload = {
      with: [
        "photo",
        "serviceTypes.icon",
        "serviceTypes.typeExpertises.icon",
        "serviceTypes.typeExpertises.servicePsychologists",
      ],
    };
    await ServiceRepository.show(id, payload)
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};

        const modified: any = {
          ...data,
          photo_id: toFileList(data.photo),
          types: data?.service_types?.map((type: any) => {
            return {
              ...type,
              icon_id: toFileList(type.icon),
              type_expertises: type?.type_expertises?.map(
                (type_expertise: any) => {
                  return {
                    ...type_expertise,
                    icon_id: toFileList(type_expertise.icon),
                    psychologist_ids: type_expertise.service_psychologists,
                  };
                },
              ),
            };
          }),
        };

        form.setFieldsValue({
          ...modified,
        });
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  };

  return (
    <>
      <Helmet>
        <title>{title} Detail</title>
      </Helmet>
      <VuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          initialValues={{
            types: [
              {
                icon_id: [],
                name: "",
                description: "",
              },
            ],
          }}
        >
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
              }}
              xs={{
                span: 24,
              }}
            >
              <Card title={""} className="mb16">
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={0}
                >
                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="title"
                        label={"Title Service"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Title",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Title",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="summary"
                        label={"Summary Service"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Summary Service",
                            }),
                          },
                        ]}
                      >
                        <TextArea
                          rows={3}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Summary Service",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="description"
                        label={"Description Service"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Description Service",
                            }),
                          },
                        ]}
                      >
                        {/*<ReactQuill*/}
                        {/*  theme="snow"*/}
                        {/*  placeholder={t("common.text.input", {*/}
                        {/*    item: "Description Service",*/}
                        {/*  })}*/}
                        {/*  modules={{*/}
                        {/*    toolbar: [*/}
                        {/*      [{ header: [1, 2, 3, 4, 5, 6, false] }],*/}
                        {/*      [*/}
                        {/*        "bold",*/}
                        {/*        "italic",*/}
                        {/*        "underline",*/}
                        {/*        "blockquote",*/}
                        {/*        "code-block",*/}
                        {/*      ],*/}
                        {/*      [{ color: [] }],*/}
                        {/*      [*/}
                        {/*        { align: [] },*/}
                        {/*        { list: "ordered" },*/}
                        {/*        { list: "bullet" },*/}
                        {/*        { indent: "-1" },*/}
                        {/*        { indent: "+1" },*/}
                        {/*      ],*/}
                        {/*      [{ script: "sub" }, { script: "super" }],*/}
                        {/*      ["clean"],*/}
                        {/*    ],*/}
                        {/*  }}*/}
                        {/*/>*/}
                        <VuiEditor
                          disabled={disable.get()}
                          apiKey={env.getTinyApiKey()}
                          init={{
                            ...vuiEditorBaseInit,
                            placeholder: t("common.text.input", {
                              item: "Description Service",
                            }),
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        label={
                          <div>
                            Image{" "}
                            <span className="optional-form-label">
                              (
                              {t("common.text.recommendedSize", {
                                item: IMAGE_RECOMMENDATION_SIZE.service
                                  .ourService,
                              })}
                              )
                            </span>
                          </div>
                        }
                        name="photo_id"
                      >
                        <VuiUploadImages isUploadFirst />
                      </Form.Item>
                    </div>
                  </div>

                  <Form.List name="types">
                    {(fields, { add, remove }) => (
                      <Space
                        style={{
                          width: "100%",
                        }}
                        direction="vertical"
                        size={15}
                      >
                        {fields.map(({ key, name, ...restField }) => (
                          <Card title={""} key={key}>
                            <Row align={"middle"} justify={"space-between"}>
                              <Title level={5}>
                                Type Of Services {name + 1}
                              </Title>
                              {name !== 0 ? (
                                <DeleteOutlined onClick={() => remove(name)} />
                              ) : (
                                ""
                              )}
                            </Row>

                            <div className="vui-form-group type-column">
                              <div className="input-section">
                                <Form.Item
                                  {...restField}
                                  name={[name, "icon_id"]}
                                  label={
                                    <div>
                                      Icon{" "}
                                      <span className="optional-form-label">
                                        (
                                        {t("common.text.recommendedSize", {
                                          item: IMAGE_RECOMMENDATION_SIZE
                                            .service.typeOfService,
                                        })}
                                        )
                                      </span>
                                    </div>
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: t("validation.required", {
                                        item: "Icon",
                                      }),
                                    },
                                  ]}
                                >
                                  <VuiUploadImages isUploadFirst />
                                </Form.Item>
                              </div>
                            </div>

                            <div className="vui-form-group type-column">
                              <div className="input-section">
                                <Form.Item
                                  {...restField}
                                  name={[name, "name"]}
                                  label={"Name"}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("validation.required", {
                                        item: "Name",
                                      }),
                                    },
                                  ]}
                                >
                                  <Input
                                    size={"large"}
                                    placeholder={t("common.text.input", {
                                      item: "Name",
                                    })}
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <div className="vui-form-group type-column">
                              <div className="input-section">
                                <Form.Item
                                  {...restField}
                                  name={[name, "description"]}
                                  label={"Description"}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("validation.required", {
                                        item: "Description",
                                      }),
                                    },
                                  ]}
                                >
                                  <VuiEditor
                                    disabled={disable.get()}
                                    apiKey={env.getTinyApiKey()}
                                    init={{
                                      ...vuiEditorBaseInit,
                                      placeholder: t("common.text.input", {
                                        item: "Description",
                                      }),
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>

                            <Form.List name={[name, "type_expertises"]}>
                              {(fields2, { add: add2, remove: remove2 }) => (
                                <Space
                                  style={{
                                    width: "100%",
                                  }}
                                  direction="vertical"
                                  size={15}
                                >
                                  {fields2.map((field2) => (
                                    <Card title={""} key={field2.key}>
                                      <Row
                                        align={"middle"}
                                        justify={"space-between"}
                                      >
                                        <Title level={5}>
                                          Area Of Expertise {field2.name + 1}
                                        </Title>

                                        <DeleteOutlined
                                          onClick={() => remove2(field2.name)}
                                        />
                                      </Row>

                                      <div className="vui-form-group type-column">
                                        <div className="input-section">
                                          <Form.Item
                                            {...field2}
                                            name={[field2.name, "name"]}
                                            label={"Name"}
                                            rules={[
                                              {
                                                required: true,
                                                message: t(
                                                  "validation.required",
                                                  {
                                                    item: "Name",
                                                  },
                                                ),
                                              },
                                            ]}
                                          >
                                            <Input
                                              size={"large"}
                                              placeholder={t(
                                                "common.text.input",
                                                {
                                                  item: "Name",
                                                },
                                              )}
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>

                                      <div className="vui-form-group type-column">
                                        <div className="input-section">
                                          <Form.Item
                                            {...field2}
                                            name={[field2.name, "description"]}
                                            label={"Description"}
                                            rules={[
                                              {
                                                required: true,
                                                message: t(
                                                  "validation.required",
                                                  {
                                                    item: "Description",
                                                  },
                                                ),
                                              },
                                            ]}
                                          >
                                            <VuiEditor
                                              disabled={disable.get()}
                                              apiKey={env.getTinyApiKey()}
                                              init={{
                                                ...vuiEditorBaseInit,
                                                placeholder: t(
                                                  "common.text.input",
                                                  {
                                                    item: "Description",
                                                  },
                                                ),
                                              }}
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>

                                      <div className="vui-form-group type-column">
                                        <div className="input-section">
                                          <Form.Item
                                            {...field2}
                                            name={[field2.name, "icon_id"]}
                                            label={
                                              <div>
                                                Icon{" "}
                                                <span className="optional-form-label">
                                                  (
                                                  {t(
                                                    "common.text.recommendedSize",
                                                    {
                                                      item: IMAGE_RECOMMENDATION_SIZE
                                                        .service
                                                        .areaOfExpertise,
                                                    },
                                                  )}
                                                  )
                                                </span>
                                              </div>
                                            }
                                            rules={[
                                              {
                                                required: true,
                                                message: t(
                                                  "validation.required",
                                                  {
                                                    item: "Icon",
                                                  },
                                                ),
                                              },
                                            ]}
                                          >
                                            <VuiUploadImages isUploadFirst />
                                          </Form.Item>
                                        </div>
                                      </div>

                                      <div className="vui-form-group type-column">
                                        <div className="input-section">
                                          <Form.Item
                                            {...field2}
                                            name={[
                                              field2.name,
                                              "psychologist_ids",
                                            ]}
                                            label={"Psychologist"}
                                            rules={[
                                              {
                                                required: true,
                                                message: t(
                                                  "validation.required",
                                                  {
                                                    item: "Psychologist",
                                                  },
                                                ),
                                              },
                                            ]}
                                          >
                                            <VuiSelectMulti
                                              disabled={disable.get()}
                                              repository={
                                                PsychologistRepository
                                              }
                                              placeholder={t(
                                                "select.placeholder",
                                                {
                                                  item: "Psychologist",
                                                },
                                              )}
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                    </Card>
                                  ))}

                                  <Form.Item className={"mt16"}>
                                    <Button
                                      type="dashed"
                                      onClick={() => add2()}
                                      block
                                      icon={<PlusOutlined />}
                                    >
                                      Add Area Of Expertise
                                    </Button>
                                  </Form.Item>
                                </Space>
                              )}
                            </Form.List>
                          </Card>
                        ))}

                        <Form.Item className={"mt16"}>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Type Of Services
                          </Button>
                        </Form.Item>
                      </Space>
                    )}
                  </Form.List>
                </Space>
              </Card>
            </Col>
          </Row>

          <div className="vui-form-btn-group">
            <Button
              className="vui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Accessible
              access={
                id
                  ? ACCESS_PERMISSION.service.update
                  : ACCESS_PERMISSION.service.store
              }
            >
              <Spin spinning={loading.get()}>
                <Button
                  className="vui-btn"
                  type="primary"
                  htmlType="submit"
                  size={"large"}
                >
                  Submit
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </VuiContainer>
    </>
  );
};

export default AppServiceForm;
