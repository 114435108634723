import { Form, Input, Radio, Space, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { ICounselingFormBaseProps } from "../interface";

const { Text } = Typography;

const CounselingFormMultipleChoice: React.FC<ICounselingFormBaseProps> = ({
  question,
  disabled,
  forPrint,
}) => {
  const { t } = useTranslation();
  const formName = `${question.id}-${question.type}`;
  return (
    <Form.Item
      shouldUpdate={(prevValues, curValues) =>
        prevValues[formName] !== curValues[formName]
      }
      className="mb0"
    >
      {({ getFieldValue }) => (
        <Form.Item
          name={formName}
          label={question.question}
          rules={[
            {
              required: Boolean(question.is_required),
              message: t("validation.required", {
                item: "Answer",
              }),
            },
          ]}
          tooltip={question.tooltip}
        >
          {forPrint ? (
            <Radio.Group disabled={disabled}>
              {_.isArray(question.answers) &&
                question.answers.map((answer, index) => (
                  <Radio value={answer} key={`${index}-${formName}`}>
                    {typeof answer === "string" ? answer : ""}
                  </Radio>
                ))}
              {Boolean(question.is_others) && (
                <Radio value={"other"}>
                  <Space align="center">
                    <Text>Other</Text>
                    {getFieldValue(formName) === "other" && (
                      <Form.Item
                        className="mb0"
                        name={`${question.id}-${question.type}-other`}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Answer",
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disabled}
                          size={"middle"}
                          placeholder={t("common.text.input", {
                            item: "Answer",
                          })}
                        />
                      </Form.Item>
                    )}
                  </Space>
                </Radio>
              )}
            </Radio.Group>
          ) : (
            <Radio.Group disabled={disabled}>
              <Space direction="vertical">
                {_.isArray(question.answers) &&
                  question.answers.map((answer, index) => (
                    <Radio value={answer} key={`${index}-${formName}`}>
                      {typeof answer === "string" ? answer : ""}
                    </Radio>
                  ))}
                {Boolean(question.is_others) && (
                  <Radio value={"other"}>
                    <Space align="center">
                      <Text>Other</Text>
                      {getFieldValue(formName) === "other" && (
                        <Form.Item
                          className="mb0"
                          name={`${question.id}-${question.type}-other`}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: "Answer",
                              }),
                            },
                          ]}
                        >
                          <Input
                            disabled={disabled}
                            size={"middle"}
                            placeholder={t("common.text.input", {
                              item: "Answer",
                            })}
                          />
                        </Form.Item>
                      )}
                    </Space>
                  </Radio>
                )}
              </Space>
            </Radio.Group>
          )}
        </Form.Item>
      )}
    </Form.Item>
  );
};

export default CounselingFormMultipleChoice;
