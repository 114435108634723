import "./style.less";
import { ReactNode } from "react";
import { Space } from "antd";
import clsx from "clsx";

export interface PageFooterProps {
  title?: ReactNode;
  suffix?: ReactNode;
  spacingSuffix?: number;
  className?: string;
}

const PageFooter = (props: PageFooterProps) => {
  const { title, suffix, className, spacingSuffix = 16 } = props;

  return (
    <div
      className={clsx(["page-footer", "container", className], {
        "no-title": !title,
      })}
    >
      {title}
      {suffix && <Space size={spacingSuffix}>{suffix}</Space>}
    </div>
  );
};

export default PageFooter;
