import { Form, Select } from "antd";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { ICounselingFormBaseProps } from "../interface";

const { Option } = Select;

const CounselingFormDropDown: React.FC<ICounselingFormBaseProps> = ({
  question,
  disabled,
}) => {
  const { t } = useTranslation();
  const formName = `${question.id}-${question.type}`;
  return (
    <Form.Item
      name={formName}
      label={question.question}
      rules={[
        {
          required: Boolean(question.is_required),
          message: t("validation.required", {
            item: "Answer",
          }),
        },
      ]}
      tooltip={question.tooltip}
    >
      <Select
        disabled={disabled}
        placeholder={t("common.text.input", {
          item: "Answer",
        })}
      >
        {_.isArray(question.answers) &&
          question.answers.map((answer, index: number) => {
            return (
              <Option key={`${index}-${formName}`} value={answer}>
                {typeof answer === "string" ? answer : ""}
              </Option>
            );
          })}
      </Select>
    </Form.Item>
  );
};

export default CounselingFormDropDown;
