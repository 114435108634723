import { ACCESS_PERMISSION } from "./";

export const defaultMenus = [
  {
    title: "Dashboard",
    key: "dashboard",
    link: "/dashboard",
    access: "IGNORE",
    // icon: <AppstoreOutlined />
  },
  {
    title: "Informational Page",
    key: "page",
    link: "/page",
    access: ACCESS_PERMISSION.page.index,
    // icon: <AppstoreOutlined />
  },
  {
    title: "Service",
    key: "service",
    link: "/service",
    access: ACCESS_PERMISSION.service.index,
    // icon: <AppstoreOutlined />
  },
  {
    title: "Schedule",
    key: "schedule",
    link: "/schedule",
    access: ACCESS_PERMISSION.schedule.index,
    // icon: <AppstoreOutlined />
  },

  {
    title: "Booking",
    key: "booking",
    access: ACCESS_PERMISSION.schedule.index,
    link: "/booking",
    // icon: <SettingOutlined />,
  },
  {
    title: "Assessment & Programs",
    key: "assessment",
    link: "/assessment",
    access: ACCESS_PERMISSION.assessment.index,
  },
  {
    title: "Package",
    key: "package",
    link: "/package",
    access: ACCESS_PERMISSION.event.show,
    // icon: <AppstoreOutlined />
  },
  {
    title: "Event",
    key: "event",
    link: "/event",
    access: ACCESS_PERMISSION.event.index,
    // icon: <AppstoreOutlined />
  },
  {
    title: "Form",
    key: "custom-form",
    access: ACCESS_PERMISSION.form.index,
    link: "/custom-form",
  },
  {
    title: "Insight & Research",
    key: "insight_and_research",
    access: "IGNORE",
    link: "/insight_and_research",
    // icon: <SettingOutlined />,
    subMenus: [
      {
        title: "Article",
        key: "article",
        access: ACCESS_PERMISSION.article.index,
        link: "/insight_and_research/article",
      },
      {
        title: "Research",
        key: "research",
        access: ACCESS_PERMISSION.research.index,
        link: "/insight_and_research/research",
      },
      {
        title: "Tags",
        key: "tags",
        access: ACCESS_PERMISSION.tag.index,
        link: "/insight_and_research/tags",
      },
    ],
  },
  {
    title: "Psychologist",
    key: "psychologist",
    access: "IGNORE",
    link: "/psychologist",
    // icon: <SettingOutlined />,
    subMenus: [
      {
        title: "List",
        key: "psychologist-list",
        access: ACCESS_PERMISSION.psychologist.index,
        link: "/psychologist/psychologist-list",
      },
      {
        title: "Expertise",
        key: "expertise",
        access: ACCESS_PERMISSION.expertise.index,
        link: "/psychologist/expertise",
      },
      {
        title: "Category",
        key: "psychologist-category",
        access: ACCESS_PERMISSION.category.index,
        link: "/psychologist/psychologist-category",
      },
    ],
  },
  {
    title: "Report",
    key: "report",
    link: "/report",
    access: ACCESS_PERMISSION.report.index,
    // icon: <AppstoreOutlined />
  },
  {
    title: "Customer",
    key: "customer",
    link: "/customer",
    access: ACCESS_PERMISSION.customer.index,
    // icon: <AppstoreOutlined />
  },
  {
    title: "Subscribe",
    key: "subscribe",
    link: "/subscribe",
    access: ACCESS_PERMISSION.subscribe.index,
    // icon: <AppstoreOutlined />
  },

  {
    title: "Contact Form",
    key: "contact-form",
    link: "/contact-form",
    access: ACCESS_PERMISSION.contactForm.index,
    // icon: <AppstoreOutlined />
  },

  {
    title: "Admin",
    key: "admin",
    access: "IGNORE",
    link: "/admin",
    // icon: <SettingOutlined />,
    subMenus: [
      {
        title: "User",
        key: "user",
        access: ACCESS_PERMISSION.user.index,
        link: "/admin/user",
      },
      {
        title: "Role",
        key: "role",
        access: ACCESS_PERMISSION.role.index,
        link: "/admin/role",
      },
    ],
  },
  {
    title: "Account",
    key: "account",
    access: "IGNORE",
    link: "/account",
    // icon: <SettingOutlined />,
    subMenus: [
      {
        title: "Profile",
        key: "profile",
        access: "IGNORE",
        link: "/account/profile",
      },
      {
        title: "Change Password",
        key: "change-password",
        access: "IGNORE",
        link: "/account/change-password",
      },
    ],
  },

  {
    title: "Settings",
    key: "settings",
    link: "/settings",
    access: ACCESS_PERMISSION.setting.show,
    // icon: <AppstoreOutlined />
  },
  {
    title: "Social",
    key: "social",
    link: "/social",
    access: ACCESS_PERMISSION.setting.show,
    // icon: <AppstoreOutlined />
  },
];

export const psychologistMenus = [
  {
    title: "Dashboard",
    key: "dashboard",
    link: "/dashboard",
    access: "IGNORE",
    // icon: <AppstoreOutlined />
  },
  {
    title: "Schedule",
    key: "schedule",
    access: "IGNORE",
    link: "/schedule",
    subMenus: [
      {
        title: "Upcoming Schedule",
        key: "upcoming-schedule",
        access: "IGNORE",
        link: "/schedule/upcoming-schedule",
      },
      {
        title: "Schedule History",
        key: "schedule-history",
        access: "IGNORE",
        link: "/schedule/schedule-history",
      },
    ],
  },
];
