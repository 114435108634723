import { Modal, Space } from "antd";
import Button from "antd/es/button";
import React from "react";
import FormItemView from "../../../../@vodea/vodea-ui/components/Form/FormItemView";
import { Link } from "react-router-dom";
import { BookingSession } from "../../../../entities/booking";
import moment from "moment";

interface SessionModalProps {
  visible: boolean;
  onClose: () => void;
  sessions?: BookingSession[];
}

const SessionModal: React.FC<SessionModalProps> = ({
  visible,
  onClose,
  sessions,
}) => {
  return (
    <Modal
      title={"All Session"}
      centered
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <div>
        <Space direction="vertical" size={20}>
          {sessions?.map((item, i) => {
            return (
              <FormItemView
                label={<b>Season {i + 1}</b>}
                spaceSize={2}
                value={
                  <Link
                    to={`/schedule/detail/${item.schedule_id}`}
                    target="_blank"
                  >
                    <u>
                      {moment(item.date).format("DD MMM YYYY")} |{" "}
                      {item.time_from} - {item.time_to}
                    </u>
                  </Link>
                }
              />
            );
          })}
        </Space>
      </div>
    </Modal>
  );
};

export default SessionModal;
