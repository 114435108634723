import { Modal, TimePicker } from "antd";
import Button from "antd/es/button";
import React, { useEffect, useState } from "react";
import { RangePickerProps } from "antd/lib/date-picker";
import { StoreValue } from "antd/es/form/interface";

const timePickerFormat = "HH:mm";

interface ModalAddTimeProps {
  onSave?: (defaultValue?: StoreValue, insertIndex?: number) => void;
  visible: boolean;
  onClose: () => void;
}

const ModalAddTime: React.FC<ModalAddTimeProps> = ({
  onSave,
  visible,
  onClose,
}) => {
  const [selectedTime, setSelectedTime] = useState<RangePickerProps["value"]>();

  const handleOnOk = () => {
    if (onSave) {
      onSave(selectedTime);
    }
    onClose();
  };

  useEffect(() => {
    if (visible) {
      setSelectedTime(undefined);
    }
  }, [visible]);

  return (
    <Modal
      title={"Add Time"}
      centered
      visible={visible}
      onOk={handleOnOk}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          disabled={!selectedTime}
          type="primary"
          onClick={handleOnOk}
        >
          Submit
        </Button>,
      ]}
    >
      <div className="content">
        <TimePicker.RangePicker
          value={selectedTime}
          size="large"
          style={{
            width: "100%",
          }}
          format={timePickerFormat}
          onChange={(val) => setSelectedTime(val)}
        />
      </div>
    </Modal>
  );
};

export default ModalAddTime;
