/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import _ from "lodash";
import { AxiosError } from "axios";

export const useFormErrors = () => {
  const [error, setError] = useState();

  const setFormError = (e: AxiosError) => {
    const errors: any = _.get(e, "response.data.errors");
    if (errors) {
      const newFormError: any = {};

      Object.keys(errors).forEach((key) => {
        setNewFormError(newFormError, key, errors[key][0]);
      });

      setError(newFormError);
    }
  };

  const resetFormError = () => {
    setError(undefined);
  };

  function setNewFormError(obj: any, path: string, value: any) {
    const keys = path.split(".");
    let current = obj;

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (i === keys.length - 1) {
        current[key] = value;
      } else {
        if (!current[key]) {
          current[key] = {};
        }
        current = current[key];
      }
    }

    return obj;
  }
  const getFormError = (name: string) => {
    if (error) {
      return _.get(error, name);
    }
  };

  const getFormValidateStatus = (name: string) => {
    if (getFormError(name)) {
      return "error";
    }

    return undefined;
  };

  const clearFormError = (changedValues: any, _allValues: any) => {
    if (error) {
      Object.keys(changedValues).forEach((key) => {
        const value = changedValues[key];
        const name = getFieldName(value, key);

        setError((s: any) => ({
          ...s,
          [name]: undefined,
        }));
      });
    }
  };

  const clearFormErrorByName = (names: string | string[]) => {
    if (error) {
      if (_.isArray(names)) {
        names.forEach((name) => {
          setError((s: any) => ({
            ...s,
            [name]: undefined,
          }));
        });
      } else {
        setError((s: any) => ({
          ...s,
          [names]: undefined,
        }));
      }
    }
  };

  const clearAllFormError = () => {
    if (error) {
      setError(undefined);
    }
  };

  const getFieldName: any = (value: any, key: string) => {
    const name = key;
    if (_.isArray(value)) {
      const lastValue = value[value.length - 1];
      const stringValues = value.find((item) => typeof item === "string");

      if (lastValue === undefined || stringValues) {
        return name;
      }

      const findIndex = value.findIndex((item) => !!item);
      const childName = findIndex !== -1 ? name + "." + findIndex : name;
      if (_.isArray(value[findIndex])) {
        return getFieldName(value[findIndex], childName);
      } else {
        const childValue = value[findIndex];
        if (childValue) {
          return childName + "." + Object?.keys(childValue)[0];
        } else {
          return childName;
        }
      }
    } else {
      return name;
    }
  };

  return {
    getFormError,
    setFormError,
    getFormValidateStatus,
    clearFormError,
    resetFormError,
    clearFormErrorByName,
    clearAllFormError,
  };
};
