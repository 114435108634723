import { SearchOutlined } from "@ant-design/icons";
import { useState } from "@hookstate/core";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Input from "antd/es/input";
import Table from "antd/es/table";
import { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useIsMounted } from "../../../../@vodea/utilities/hooks";
import useDebounce from "../../../../@vodea/utilities/hooks/useDebounce";
import VuiContainer from "../../../../@vodea/vodea-ui/components/Container";
import VuiModalConfirmation from "../../../../@vodea/vodea-ui/components/Modal/Confirmation";
import VuiSectionTitle from "../../../../@vodea/vodea-ui/components/Sections/Title";
import { recentFilterKey } from "../../../../constant";
import {
  handleBackendError,
  openNotification,
  overideTableSortOrder,
  recentFilterData,
} from "../../../../functions/global";
import PsychologistScheduleRepository from "../../../../repositories/PsychologistScheduleRepository";
import StatusTag from "../../../../components/StatusTag";
import _ from "lodash";
import { scheduleMethod } from "../../../../@vodea/utilities/string";
import { timeFormat } from "../../../../@vodea/utilities/date";
import Button from "antd/es/button";
import VuiFilterDateRange from "../../../../@vodea/vodea-ui/components/Filter/DateRange";
import VuiSelectMulti from "../../../../@vodea/vodea-ui/components/Select/Multi";
import CustomerRepository from "../../../../repositories/CustomerRepository";
import { Collapse, Select } from "antd";
import {
  scheduleSessionTypes,
  scheduleTypes,
} from "../../../../constant/schedule";
import {
  ScheduleSessionType,
  ScheduleType,
} from "../../../../entities/schedule";
import ConstantRepository from "../../../../repositories/ConstantRepository";

const { Panel } = Collapse;

var qs = require("qs");

const breadcrumbs = [
  {
    label: "Upcoming Schedule",
    link: "/upcoming-schedule",
  },
];

const AppPsychologistScheduleList: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let fullPath = window.location.pathname;

  const tablePage = useState<number>(parseInt(searchParams.get("page") || "1"));
  const tablePerPage = useState<number>(
    parseInt(searchParams.get("perPage") || "10")
  );
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const tableSort = useState<{
    sortField: any;
    sortOrder: any;
  }>({
    sortField: searchParams.get("sortField"),
    sortOrder: searchParams.get("sortOrder"),
  });
  const tableData = useState([]);
  const totalData = useState<number>(0);
  const recentCustomers = useMemo(
    () => recentFilterData.get(recentFilterKey.customer),
    []
  );
  const [selectedCustomers, setSelectedCustomers] = React.useState<any[]>([]);

  const [selectedSessionType, setSelectedSessionType] =
    React.useState<ScheduleSessionType>();
  const [selectedType, setSelectedType] = React.useState<ScheduleType>();

  const recentSelectedStatus = useMemo(
    () => recentFilterData.get(recentFilterKey.psychologistScheduleStatus),
    []
  );
  const [selectedStatus, setSelectedStatus] = React.useState<any[]>([]);

  const [filterDate, setFilterDate] = React.useState<any>([
    searchParams.get("start_date")
      ? moment(searchParams.get("start_date"), "YYYY-MM-DD")
      : moment().startOf("month"),
    searchParams.get("end_date")
      ? moment(searchParams.get("end_date"), "YYYY-MM-DD")
      : moment().endOf("month"),
  ]);

  const getTableData = async (reset: boolean = false) => {
    setTableLoading(true);

    if (reset) tablePage.set(1);

    let params: any = {
      page: tablePage.get(),
      order_by: tableSort.sortField.get(),
      sorted_by: tableSort.sortOrder.get(),
      per_page: tablePerPage.get(),
      session_type: selectedSessionType,
      type: selectedType,
    };

    if (debouncedSearch) {
      params.search = debouncedSearch;
    }

    if (!params.order_by) {
      params.order_by = "created_at";
      params.sorted_by = "desc";
    }

    Object.assign(params, {
      start_date: filterDate[0].format("YYYY-MM-DD"),
    });
    Object.assign(params, {
      end_date: filterDate[1].format("YYYY-MM-DD"),
    });

    const customers = _.map(selectedCustomers, "id");

    if (customers) {
      Object.assign(params, {
        customer: customers,
      });
    }

    const status = _.map(selectedStatus, "id");

    if (status) {
      Object.assign(params, {
        psychologist_schedule_status: status,
      });
    }

    await PsychologistScheduleRepository.all(params)
      .then((res: AxiosResponse) => {
        if (res.data.data) {
          tableData.set(res.data.data);
        }

        totalData.set(res.data?.meta?.total || 0);

        setTableLoading(false);
      })
      .catch((e: AxiosError) => {
        setTableLoading(false);
      });

    setTableLoading(false);
  };

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    tablePage.set(pagination.current);
    tablePerPage.set(pagination.pageSize);

    if (sorter.order) {
      const sortOrder = overideTableSortOrder(sorter.order);
      tableSort.sortField.set(sorter.field);
      tableSort.sortOrder.set(sortOrder);
    } else {
      tableSort.sortField.set(null);
      tableSort.sortOrder.set(null);
    }

    setFilterDataToQuery();
  };

  // For Search Input
  const [search, setSearch] = React.useState<string>("");
  const debouncedSearch = useDebounce<string>(search, 1000);

  useEffect(() => {
    if (!isMounted) {
      setFilterDataToQuery();
    }
    // eslint-disable-next-line
  }, [debouncedSearch]);

  useEffect(() => {
    setFilterDataToQuery();
    // eslint-disable-next-line
  }, [
    filterDate,
    selectedCustomers,
    selectedSessionType,
    selectedType,
    selectedStatus,
  ]);

  const getFilterDataFromQuery = () => {
    const keyword = searchParams.get("keyword");

    if (keyword) {
      setSearch(keyword);
    }

    if (!recentCustomers) {
      searchParams.delete("customer");
    } else {
      const customers = _.map(searchParams.getAll("customer"), (item) =>
        parseInt(item)
      );

      if (customers) {
        const customerFromStorage = recentFilterData.getSelectedFilter(
          recentCustomers,
          customers
        );

        setSelectedCustomers(customerFromStorage);
      } else {
        localStorage.removeItem(recentFilterKey.customer);
      }

      if (!recentSelectedStatus) {
        searchParams.delete("status");
      } else {
        const status = _.map(searchParams.getAll("status"), (item) =>
          parseInt(item)
        );
        if (status) {
          const statusFromStorage = recentFilterData.getSelectedFilter(
            recentSelectedStatus,
            status
          );

          setSelectedStatus(statusFromStorage);
        } else {
          localStorage.removeItem(recentFilterKey.psychologistScheduleStatus);
        }
      }
    }
  };

  useMemo(() => {
    getFilterDataFromQuery();
    // eslint-disable-next-line
  }, []);

  const setFilterDataToQuery = () => {
    let params = {};
    let reset = false;

    if (debouncedSearch) {
      Object.assign(params, {
        keyword: debouncedSearch,
      });

      if (debouncedSearch !== searchParams.get("keyword")) {
        reset = true;
      }
    }

    if (tablePage.get() !== 1) {
      if (reset) {
        Object.assign(params, {
          page: 1,
        });
      } else {
        Object.assign(params, {
          page: tablePage.get(),
        });
      }
    }

    if (tablePerPage.get() !== 10) {
      Object.assign(params, {
        perPage: tablePerPage.get(),
      });
    }

    if (tableSort.sortField.get()) {
      Object.assign(params, {
        sortField: tableSort.sortField.get(),
      });
    }

    if (tableSort.sortOrder.get()) {
      Object.assign(params, {
        sortOrder: tableSort.sortOrder.get(),
      });
    }

    if (selectedCustomers) {
      const multiValue = _.map(selectedCustomers, "id");

      Object.assign(params, {
        customer: multiValue,
      });
    }

    if (selectedSessionType) {
      Object.assign(params, {
        sessionType: selectedSessionType,
      });
    }

    if (selectedType) {
      Object.assign(params, {
        type: selectedType,
      });
    }

    if (selectedStatus) {
      const multiValue = _.map(selectedStatus, "id");

      Object.assign(params, {
        status: multiValue,
      });
    }

    if (filterDate.length > 0 && filterDate[0] !== null) {
      Object.assign(params, {
        start_date: filterDate[0].format("YYYY-MM-DD"),
      });
      Object.assign(params, {
        end_date: filterDate[1].format("YYYY-MM-DD"),
      });
    }

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("");
    }

    getTableData(reset);
  };

  const columns: any = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "date" && tableSort.sortOrder.get(),
      render: (text: any) => {
        return moment(text).format("D MMM YYYY");
      },
    },

    {
      title: "Patient Name",
      dataIndex: "patient_name",
      key: "patient_name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "patient_name" &&
        tableSort.sortOrder.get(),
    },
    {
      title: "Method",
      dataIndex: "type",
      key: "type",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "type" && tableSort.sortOrder.get(),
      render: (val: string) => scheduleMethod(val),
    },
    {
      title: "Type",
      dataIndex: "session_type",
      key: "session_type",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "session_type" &&
        tableSort.sortOrder.get(),
      render: (val: string) => _.startCase(_.lowerCase(val)),
    },
    {
      title: "Start Time - End Time",
      key: "time",
      render: (text: string, record: any) => {
        return `${timeFormat(record.time_from)} - ${timeFormat(
          record.time_to
        )}`;
      },
    },
    {
      title: "Status",
      dataIndex: "status_name",
      key: "status_name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "status_name" &&
        tableSort.sortOrder.get(),
      render: (text: string) => <StatusTag title={text} />,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (text: any, record: any) => {
        return <Link to={fullPath + "/" + record.id}>View</Link>;
      },
    },
  ];

  // Modal
  const showDeleteModal = useState(false);
  const selectedDeleteId = useState(0);

  const setDeleteModal = (id: any) => {
    selectedDeleteId.set(id);
    showDeleteModal.set(true);
  };

  const deleteModalCallback = async () => {
    if (selectedDeleteId.get()) {
      await PsychologistScheduleRepository.delete(selectedDeleteId.get())
        .then((res: AxiosResponse) => {
          showDeleteModal.set(false);
          selectedDeleteId.set(0);
          openNotification(
            "success",
            t("notification.success.deleteItem", { item: "Issue Tracking" })
          );
          getTableData(true);
        })
        .catch((e: AxiosError) => {
          showDeleteModal.set(false);
          selectedDeleteId.set(0);
          handleBackendError(e, t("notification.error.default"));
        });
    }
  };

  const handleDateRangeCallback = (dates: any, dateString?: any) => {
    setFilterDate(dates);
  };

  const handleReset = (e: any) => {
    e.stopPropagation();
    setSelectedCustomers([]);
    setSelectedSessionType(undefined);
    setSelectedType(undefined);
    setFilterDate([moment().startOf("month"), moment().endOf("month")]);
  };

  return (
    <>
      <Helmet>
        <title>Schedule</title>
      </Helmet>

      <VuiContainer>
        <VuiSectionTitle
          title={"Upcoming Schedule"}
          breadcrumbs={breadcrumbs}
        />

        <Collapse
          className="mb16"
          defaultActiveKey={["1"]}
          expandIconPosition="end"
        >
          <Panel
            header="Filter"
            key="1"
            extra={
              <Button type="link" onClick={handleReset}>
                Reset
              </Button>
            }
          >
            <Row className="mb6" gutter={[10, 10]}>
              <Col xs={24}>
                <Row>
                  <Col xs={24} lg={12}>
                    <VuiFilterDateRange
                      defaultValue={filterDate}
                      callback={handleDateRangeCallback}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} lg={8}>
                <VuiSelectMulti
                  value={selectedCustomers}
                  onChange={(val: any) => {
                    tablePage.set(1);
                    setSelectedCustomers(val);
                    recentFilterData.save(recentFilterKey.customer, val);
                  }}
                  repository={CustomerRepository}
                  selectParams={{
                    is_admin: true,
                    per_page: 100,
                  }}
                  placeholder={t("select.placeholder", {
                    item: "Patient Name",
                  })}
                />
              </Col>
              <Col xs={24} lg={8}>
                <Select
                  style={{ width: "100%" }}
                  value={selectedSessionType}
                  onChange={(val: any) => {
                    tablePage.set(1);
                    setSelectedSessionType(val);
                  }}
                  options={scheduleSessionTypes.map((item) => ({
                    label: _.capitalize(item),
                    value: item,
                  }))}
                  placeholder={t("select.placeholder", {
                    item: "Type",
                  })}
                  size="large"
                  allowClear
                />
              </Col>
              <Col xs={24} lg={8}>
                <Select
                  style={{ width: "100%" }}
                  value={selectedType}
                  onChange={(val: any) => {
                    tablePage.set(1);
                    setSelectedType(val);
                  }}
                  options={scheduleTypes.map((item) => ({
                    label: scheduleMethod(item),
                    value: item,
                  }))}
                  placeholder={t("select.placeholder", {
                    item: "Method",
                  })}
                  size="large"
                  allowClear
                />
              </Col>
              <Col xs={24} lg={8}>
                <VuiSelectMulti
                  value={selectedStatus}
                  onChange={(val: any) => {
                    tablePage.set(1);
                    setSelectedStatus(val);
                    recentFilterData.save(
                      recentFilterKey.psychologistScheduleStatus,
                      val
                    );
                  }}
                  repository={ConstantRepository}
                  selectParams={{
                    for: "psychologist_schedule_status",
                  }}
                  placeholder={t("select.placeholder", {
                    item: "Status",
                  })}
                  labelKey="label"
                  valueKey="id"
                />
              </Col>
            </Row>
          </Panel>
        </Collapse>

        <Row gutter={[10, 10]} className="mb16">
          <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
            <Input
              allowClear
              placeholder={t("common.filter.search.placeholder")}
              prefix={<SearchOutlined />}
              value={search}
              onChange={(value) => {
                setSearch(value.target.value);
              }}
            />
          </Col>
        </Row>

        <Table
          rowKey={"id"}
          bordered
          columns={columns}
          dataSource={tableData.get()}
          loading={tableLoading}
          onChange={handleTableChange}
          pagination={{
            current: tablePage.get(),
            showSizeChanger: true,
            pageSize: tablePerPage.get(),
            total: totalData.get(),
          }}
        />
      </VuiContainer>

      <VuiModalConfirmation
        show={showDeleteModal.get()}
        onOk={deleteModalCallback}
        onCancel={() => showDeleteModal.set(false)}
      />
    </>
  );
};

export default AppPsychologistScheduleList;
