import { Form } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import VuiFormTitle from "../../../@vodea/vodea-ui/components/Form/Title";
import { ICounselingFormBaseProps } from "../interface";
import VuiEditor, {
  vuiEditorBaseInit,
} from "../../../@vodea/vodea-ui/components/Editor";
import { env } from "../../../constant";

const CounselingFormParagraph: React.FC<ICounselingFormBaseProps> = ({
  question,
  disabled,
  forPrint,
}) => {
  const { t } = useTranslation();
  const formName = `${question.id}-${question.type}`;
  return (
    <Form.Item
      shouldUpdate={(prevValues, curValues) =>
        prevValues[formName] !== curValues[formName]
      }
      className="mb0"
    >
      {({ getFieldValue }) => {
        return forPrint ? (
          <div>
            <VuiFormTitle
              title={question.question}
              required={Boolean(question.is_required)}
            />

            <div
              className="text-area-input mt12 ant-input ant-input-lg"
              dangerouslySetInnerHTML={{ __html: getFieldValue(formName) }}
            />
          </div>
        ) : (
          <Form.Item
            name={formName}
            label={question.question}
            rules={[
              {
                required: Boolean(question.is_required),
                message: t("validation.required", {
                  item: "Answer",
                }),
              },
            ]}
            tooltip={question.tooltip}
          >
            <VuiEditor
              apiKey={env.getTinyApiKey()}
              init={{
                ...vuiEditorBaseInit,
              }}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default CounselingFormParagraph;
