import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useState } from "@hookstate/core";
import { Collapse, Select, TableProps } from "antd";
import Button from "antd/es/button";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Input from "antd/es/input";
import Space from "antd/es/space";
import Table, { ColumnsType, TablePaginationConfig } from "antd/es/table";
import Text from "antd/es/typography/Text";
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from "antd/lib/table/interface";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useIsMounted } from "../../../../@vodea/utilities/hooks";
import useDebounce from "../../../../@vodea/utilities/hooks/useDebounce";
import Accessible from "../../../../@vodea/vodea-ui/components/Accessible";
import VuiContainer from "../../../../@vodea/vodea-ui/components/Container";
import VuiFilterDateRange from "../../../../@vodea/vodea-ui/components/Filter/DateRange";
import VuiModalConfirmation from "../../../../@vodea/vodea-ui/components/Modal/Confirmation";
import VuiSectionTitle from "../../../../@vodea/vodea-ui/components/Sections/Title";
import { ACCESS_PERMISSION, recentFilterKey } from "../../../../constant";
import {
  handleBackendError,
  openNotification,
  overideTableSortOrder,
  recentFilterData,
} from "../../../../functions/global";
import ScheduleRepository from "../../../../repositories/ScheduleRepository";
import ConstantRepository from "../../../../repositories/ConstantRepository";
import PsychologistRepository from "../../../../repositories/PsychologistRepository";
import CustomerRepository from "../../../../repositories/CustomerRepository";
import "./style.less";
import { $clone } from "../../../../@vodea/utilities";
import {
  Schedule,
  ScheduleSessionType,
  ScheduleType,
} from "../../../../entities/schedule";
import { Collection } from "../../../../entities/base/collection";
import VuiSelectMulti from "../../../../@vodea/vodea-ui/components/Select/Multi";
import {
  scheduleSessionTypes,
  scheduleTypes,
} from "../../../../constant/schedule";
import StatusTag from "../../../../components/StatusTag";
import PageFooter from "../../../../components/PageFooter";

var qs = require("qs");

const statusAvailable = "Available";

const statusExpired = "Expired";

const { Panel } = Collapse;

interface AppScheduleListProps {
  isBooking?: boolean;
}

const AppScheduleList: React.FC<AppScheduleListProps> = ({ isBooking }) => {
  let fullPath = window.location.pathname;
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTableRows, setSelectedTableRows] = React.useState<any[]>([]);
  const tablePage = useState<number>(parseInt(searchParams.get("page") || "1"));
  const tablePerPage = useState<number>(
    parseInt(searchParams.get("perPage") || "10"),
  );
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const tableSort = useState<{
    sortField: any;
    sortOrder: any;
  }>({
    sortField: searchParams.get("sortField"),
    sortOrder: searchParams.get("sortOrder"),
  });
  const tableData = useState<Schedule[]>([]);
  const totalData = useState<number>(0);

  const title = isBooking ? "Booking" : "Schedule";

  const breadcrumbs = [
    {
      label: title,
      link: isBooking ? "/booking" : "/schedule",
    },
  ];

  // filter
  const recentPsychologist = useMemo(
    () => recentFilterData.get(recentFilterKey.psychologist),
    [],
  );
  const [selectedPsychologist, setSelectedPsychologist] = React.useState<any[]>(
    [],
  );
  const recentCustomers = useMemo(
    () => recentFilterData.get(recentFilterKey.customer),
    [],
  );
  const [selectedCustomers, setSelectedCustomers] = React.useState<any[]>([]);

  const recentBookingStatus = useMemo(
    () => recentFilterData.get(recentFilterKey.bookingStatus),
    [],
  );
  const [selectedBookingStatus, setSelectedBookingStatus] = React.useState<
    any[]
  >([]);

  const [selectedSessionType, setSelectedSessionType] =
    React.useState<ScheduleSessionType>();
  const [selectedType, setSelectedType] = React.useState<ScheduleType>();

  const [filterDate, setFilterDate] = React.useState<any>([
    searchParams.get("start_date")
      ? moment(searchParams.get("start_date"), "YYYY-MM-DD")
      : moment().startOf("month"),
    searchParams.get("end_date")
      ? moment(searchParams.get("end_date"), "YYYY-MM-DD")
      : moment().endOf("month"),
  ]);

  const handleDateRangeCallback = (dates: any, dateString?: any) => {
    setFilterDate(dates);
  };

  const rowSelection: TableProps<any>["rowSelection"] = {
    selectedRowKeys: selectedTableRows.map((item) => item.id),
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableRows($clone(selectedRows));
    },
  };

  const getTableData = async (reset: boolean = false) => {
    setTableLoading(true);

    if (reset) tablePage.set(1);

    let params = {
      page: tablePage.get(),
      search: debouncedSearch,
      order_by: tableSort.sortField.get(),
      sorted_by: tableSort.sortOrder.get(),
      per_page: tablePerPage.get(),
      session_type: selectedSessionType,
      type: selectedType,
    };

    if (!params.order_by) {
      params.order_by = isBooking ? "booking_date" : "date";
      params.sorted_by = "desc";
    }

    if (isBooking) {
      Object.assign(params, {
        is_booking: true,
      });
    } else {
      Object.assign(params, {
        is_schedule: true,
      });
    }

    const psychologist = _.map(selectedPsychologist, "id");

    if (psychologist) {
      Object.assign(params, {
        psychologist: psychologist,
      });
    }

    const customers = _.map(selectedCustomers, "id");

    if (customers) {
      Object.assign(params, {
        customer: customers,
      });
    }

    const bookingStatus = _.map(selectedBookingStatus, "id");

    if (bookingStatus) {
      Object.assign(params, {
        status: bookingStatus,
      });
    }

    if (filterDate.length > 0 && filterDate[0] !== null) {
      if (isBooking) {
        Object.assign(params, {
          booking_date_from: filterDate[0].format("YYYY-MM-DD"),
        });
        Object.assign(params, {
          booking_date_to: filterDate[1].format("YYYY-MM-DD"),
        });
      } else {
        Object.assign(params, {
          start_date: filterDate[0].format("YYYY-MM-DD"),
        });
        Object.assign(params, {
          end_date: filterDate[1].format("YYYY-MM-DD"),
        });
      }
    }

    await ScheduleRepository.all(params)
      .then((res: AxiosResponse<Collection<Schedule>>) => {
        if (res.data.data) {
          tableData.set(res.data.data);
        }

        totalData.set(res.data?.meta?.total || 0);

        setTableLoading(false);
      })
      .catch((e: AxiosError) => {
        setTableLoading(false);
      });

    setTableLoading(false);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Schedule> | SorterResult<Schedule>[],
    extra: TableCurrentDataSource<Schedule>,
  ) => {
    if (extra.action === "paginate") {
      if (pagination.current) {
        tablePage.set(pagination.current);
      }
      if (pagination.pageSize) {
        tablePerPage.set(pagination.pageSize);
      }
    }

    if (!_.isArray(sorter)) {
      if (sorter.order) {
        const sortOrder = overideTableSortOrder(sorter.order);
        tableSort.sortField.set(sorter.field);
        tableSort.sortOrder.set(sortOrder);
      } else {
        tableSort.sortField.set(null);
        tableSort.sortOrder.set(null);
      }
    }

    setFilterDataToQuery();
  };

  // For Search Input
  const [search, setSearch] = React.useState<string>("");
  const debouncedSearch = useDebounce<string>(search, 1000);

  useEffect(() => {
    if (!isMounted) {
      setFilterDataToQuery();
    }
    // eslint-disable-next-line
  }, [debouncedSearch]);

  useEffect(() => {
    setFilterDataToQuery();
    // eslint-disable-next-line
  }, [
    selectedPsychologist,
    selectedBookingStatus,
    filterDate,
    selectedCustomers,
    selectedSessionType,
    selectedType,
  ]);

  const getFilterDataFromQuery = () => {
    const keyword = searchParams.get("keyword");

    if (keyword) {
      setSearch(keyword);
    }

    if (!recentPsychologist) {
      searchParams.delete("psychologist");
    } else {
      const psychologist = _.map(searchParams.getAll("psychologist"), (item) =>
        parseInt(item),
      );

      if (psychologist) {
        const psychologistFromStorage = recentFilterData.getSelectedFilter(
          recentPsychologist,
          psychologist,
        );

        setSelectedPsychologist(psychologistFromStorage);
      } else {
        localStorage.removeItem(recentFilterKey.psychologist);
      }
    }

    if (!recentCustomers) {
      searchParams.delete("customer");
    } else {
      const customers = _.map(searchParams.getAll("customer"), (item) =>
        parseInt(item),
      );

      if (customers) {
        const customerFromStorage = recentFilterData.getSelectedFilter(
          recentCustomers,
          customers,
        );

        setSelectedCustomers(customerFromStorage);
      } else {
        localStorage.removeItem(recentFilterKey.customer);
      }
    }

    if (!recentBookingStatus) {
      searchParams.delete("status");
    } else {
      const bookingStatus = _.map(searchParams.getAll("status"), (item) =>
        parseInt(item),
      );
      if (bookingStatus) {
        const bookingStatusFromStorage = recentFilterData.getSelectedFilter(
          recentBookingStatus,
          bookingStatus,
        );

        setSelectedBookingStatus(bookingStatusFromStorage);
      } else {
        localStorage.removeItem(recentFilterKey.bookingStatus);
      }
    }
  };

  useMemo(() => {
    getFilterDataFromQuery();
    // eslint-disable-next-line
  }, []);

  const setFilterDataToQuery = () => {
    let params = {};
    let reset = false;

    if (debouncedSearch) {
      Object.assign(params, {
        keyword: debouncedSearch,
      });

      if (debouncedSearch !== searchParams.get("keyword")) {
        reset = true;
      }
    }

    if (tablePage.get() !== 1) {
      if (reset) {
        Object.assign(params, {
          page: 1,
        });
      } else {
        Object.assign(params, {
          page: tablePage.get(),
        });
      }
    }

    if (tablePerPage.get() !== 10) {
      Object.assign(params, {
        perPage: tablePerPage.get(),
      });
    }

    if (tableSort.sortField.get()) {
      Object.assign(params, {
        sortField: tableSort.sortField.get(),
      });
    }

    if (tableSort.sortOrder.get()) {
      Object.assign(params, {
        sortOrder: tableSort.sortOrder.get(),
      });
    }

    if (selectedPsychologist) {
      const multiValue = _.map(selectedPsychologist, "id");

      Object.assign(params, {
        psychologist: multiValue,
      });
    }

    if (selectedCustomers) {
      const multiValue = _.map(selectedCustomers, "id");

      Object.assign(params, {
        customer: multiValue,
      });
    }

    if (selectedBookingStatus) {
      const multiValue = _.map(selectedBookingStatus, "id");

      Object.assign(params, {
        status: multiValue,
      });
    }

    if (selectedSessionType) {
      Object.assign(params, {
        sessionType: selectedSessionType,
      });
    }

    if (selectedType) {
      Object.assign(params, {
        type: selectedType,
      });
    }

    if (filterDate.length > 0 && filterDate[0] !== null) {
      Object.assign(params, {
        start_date: filterDate[0].format("YYYY-MM-DD"),
      });
      Object.assign(params, {
        end_date: filterDate[1].format("YYYY-MM-DD"),
      });
    }

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("");
    }

    getTableData(reset);
  };

  const columns: ColumnsType<Schedule> = [
    {
      title: isBooking ? "Booking Date" : "Date",
      dataIndex: isBooking ? "booking_date" : "date",
      key: isBooking ? "booking_date" : "date",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === (isBooking ? "booking_date" : "date") &&
        tableSort.sortOrder.get(),
      render: (text, record) => {
        return moment(isBooking ? record.booking_date : text).format(
          "D MMM YYYY",
        );
      },
    },
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      key: "patient_name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "patient_name" &&
        tableSort.sortOrder.get(),
    },
    {
      title: "Booking Number",
      dataIndex: "booking_number",
      key: "booking_number",
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_number",
      key: "invoice_number",
    },
    {
      title: "Type",
      dataIndex: "session_type",
      key: "session_type",
      render: (val) => _.startCase(_.lowerCase(val)),
    },
    {
      title: "Metode",
      dataIndex: "type",
      key: "type",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "type" && tableSort.sortOrder.get(),
    },
    {
      title: "Start Time",
      dataIndex: "time_from",
      key: "time_from",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "time_from" && tableSort.sortOrder.get(),
    },
    {
      title: "End Time",
      dataIndex: "time_to",
      key: "time_to",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "time_to" && tableSort.sortOrder.get(),
    },
    {
      title: "Psychologist",
      dataIndex: "psychologist_name",
      key: "psychologist_name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "psychologist_name" &&
        tableSort.sortOrder.get(),
    },
    {
      title: "Counseling Form",
      dataIndex: "form_status_name",
      key: "form_status_name",
    },
    {
      title: "Status",
      dataIndex: "status_name",
      key: "status_name",
      sorter: true,
      fixed: "right",
      defaultSortOrder:
        tableSort.sortField.get() === "status_name" &&
        tableSort.sortOrder.get(),
      render: (text) => <StatusTag title={text} />,
    },

    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (text: any, record) => {
        return (
          <Space size="middle">
            <Accessible access={ACCESS_PERMISSION.schedule.show}>
              <Link
                to={
                  isBooking
                    ? `/booking/${record.id}`
                    : [statusAvailable, statusExpired].includes(
                          record.status_name,
                        )
                      ? fullPath + "/" + record.id
                      : `${fullPath}/detail/${record.id}`
                }
              >
                View
              </Link>
            </Accessible>
            <Accessible access={ACCESS_PERMISSION.schedule.destroy}>
              <Text
                className="cursor-pointer"
                type={"danger"}
                onClick={() => setDeleteModal(record.id)}
              >
                Delete
              </Text>
            </Accessible>
          </Space>
        );
      },
    },
  ];

  // Modal
  const showDeleteModal = useState(false);
  const selectedDeleteId = useState(0);

  const setDeleteModal = (id: any) => {
    selectedDeleteId.set(id);
    showDeleteModal.set(true);
  };

  const deleteModalCallback = async () => {
    if (selectedDeleteId.get()) {
      await ScheduleRepository.delete(selectedDeleteId.get())
        .then((res: AxiosResponse) => {
          showDeleteModal.set(false);
          selectedDeleteId.set(0);
          openNotification(
            "success",
            t("notification.success.deleteItem", { item: title }),
          );
          getTableData(true);
        })
        .catch((e: AxiosError) => {
          showDeleteModal.set(false);
          selectedDeleteId.set(0);
          handleBackendError(e, t("notification.error.default"));
        });
    }
  };

  // Modal
  const showMultipleDeleteModal = useState(false);

  const multipleDeleteModalCallback = async () => {
    await ScheduleRepository.deleteMultiple({
      ids: selectedTableRows.map((item) => item.id),
    })
      .then((res: AxiosResponse) => {
        showMultipleDeleteModal.set(false);
        setSelectedTableRows([]);
        openNotification(
          "success",
          t("notification.success.deleteItem", { item: title }),
        );
        getTableData(true);
      })
      .catch((e: AxiosError) => {
        showMultipleDeleteModal.set(false);
        handleBackendError(e, t("notification.error.default"));
      });
  };

  const handleReset = (e: any) => {
    e.stopPropagation();
    setSelectedPsychologist([]);
    setSelectedBookingStatus([]);
    setSelectedCustomers([]);
    setSelectedSessionType(undefined);
    setSelectedType(undefined);
    setFilterDate([moment().startOf("month"), moment().endOf("month")]);
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="schedule-list-container">
        <VuiContainer bottomSpace={110}>
          <VuiSectionTitle title={title} breadcrumbs={breadcrumbs}>
            {!isBooking && (
              <Accessible access={ACCESS_PERMISSION.schedule.store}>
                <Button
                  onClick={() => navigate(fullPath + "/form")}
                  type="primary"
                  icon={<PlusOutlined />}
                >
                  {t("common.button.addNew")}
                </Button>
              </Accessible>
            )}
          </VuiSectionTitle>

          <Collapse
            className="mb16"
            defaultActiveKey={["1"]}
            expandIconPosition="end"
          >
            <Panel
              header="Filter"
              key="1"
              extra={
                <Button type="link" onClick={handleReset}>
                  Reset
                </Button>
              }
            >
              <Row className="mb6" gutter={[10, 10]}>
                <Col xs={24}>
                  <Row>
                    <Col xs={24} lg={12}>
                      <VuiFilterDateRange
                        defaultValue={filterDate}
                        callback={handleDateRangeCallback}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={8}>
                  <VuiSelectMulti
                    value={selectedCustomers}
                    onChange={(val: any) => {
                      tablePage.set(1);
                      setSelectedCustomers(val);
                      recentFilterData.save(recentFilterKey.customer, val);
                    }}
                    repository={CustomerRepository}
                    selectParams={{
                      is_admin: true,
                      per_page: 100,
                    }}
                    placeholder={t("select.placeholder", {
                      item: "Patient Name",
                    })}
                  />
                </Col>
                {!isBooking && (
                  <Col xs={24} lg={8}>
                    <VuiSelectMulti
                      value={selectedPsychologist}
                      onChange={(val: any) => {
                        tablePage.set(1);
                        setSelectedPsychologist(val);
                        recentFilterData.save(
                          recentFilterKey.psychologist,
                          val,
                        );
                      }}
                      repository={PsychologistRepository}
                      placeholder={t("select.placeholder", {
                        item: "Psychologist",
                      })}
                    />
                  </Col>
                )}
                <Col xs={24} lg={8}>
                  <Select
                    style={{ width: "100%" }}
                    value={selectedSessionType}
                    onChange={(val: any) => {
                      tablePage.set(1);
                      setSelectedSessionType(val);
                    }}
                    options={scheduleSessionTypes.map((item) => ({
                      label: _.capitalize(item),
                      value: item,
                    }))}
                    placeholder={t("select.placeholder", {
                      item: "Type",
                    })}
                    size="large"
                    allowClear
                  />
                </Col>
                {!isBooking && (
                  <Col xs={24} lg={8}>
                    <Select
                      style={{ width: "100%" }}
                      value={selectedType}
                      onChange={(val: any) => {
                        tablePage.set(1);
                        setSelectedType(val);
                      }}
                      options={scheduleTypes.map((item) => ({
                        label: _.capitalize(item),
                        value: item,
                      }))}
                      placeholder={t("select.placeholder", {
                        item: "Method",
                      })}
                      size="large"
                      allowClear
                    />
                  </Col>
                )}
                <Col xs={24} lg={8}>
                  <VuiSelectMulti
                    value={selectedBookingStatus}
                    onChange={(val: any) => {
                      tablePage.set(1);
                      setSelectedBookingStatus(val);
                      recentFilterData.save(recentFilterKey.bookingStatus, val);
                    }}
                    repository={ConstantRepository}
                    selectParams={{
                      for: isBooking ? "booking_status" : "schedule_status",
                    }}
                    placeholder={t("select.placeholder", {
                      item: "Status",
                    })}
                    labelKey="label"
                    valueKey="id"
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>

          <Row gutter={[10, 10]} className="mb16">
            <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
              <Input
                allowClear
                placeholder={t("common.filter.search.placeholder")}
                prefix={<SearchOutlined />}
                value={search}
                onChange={(value) => {
                  setSearch(value.target.value);
                }}
              />
            </Col>
          </Row>

          <div>
            <Table
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
                fixed: true,
              }}
              rowKey={(row) => row.id}
              scroll={{ x: "max-content" }}
              bordered
              columns={columns}
              dataSource={$clone(tableData.get())}
              loading={tableLoading}
              onChange={handleTableChange}
              pagination={{
                current: tablePage.get(),
                showSizeChanger: true,
                pageSize: tablePerPage.get(),
                total: totalData.get(),
              }}
            />
          </div>
          {selectedTableRows.length > 0 && (
            <PageFooter
              title={`${selectedTableRows.length} ${
                isBooking ? "bookings" : "schedules"
              } selected`}
              suffix={
                <Accessible access={ACCESS_PERMISSION.schedule.destroy}>
                  <Button
                    className="cursor-pointer"
                    danger
                    type="primary"
                    onClick={() => showMultipleDeleteModal.set(true)}
                  >
                    Delete
                  </Button>
                </Accessible>
              }
            />
          )}
        </VuiContainer>
      </div>

      <VuiModalConfirmation
        show={showDeleteModal.get()}
        onOk={deleteModalCallback}
        onCancel={() => showDeleteModal.set(false)}
      />
      <VuiModalConfirmation
        show={showMultipleDeleteModal.get()}
        onOk={multipleDeleteModalCallback}
        onCancel={() => showMultipleDeleteModal.set(false)}
        description={`${selectedTableRows.length} ${
          isBooking ? "bookings" : "schedules"
        } selected`}
      />
    </>
  );
};

export default AppScheduleList;
