import React, { useEffect } from "react";
import VuiContainer from "../../../../@vodea/vodea-ui/components/Container";
import PageHeader from "antd/es/page-header";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "antd/es/card";
import Space from "antd/es/space";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Form from "antd/lib/form";
import { Checkbox, Radio, Row, Spin, Switch } from "antd";
import { useState } from "@hookstate/core";
import Select from "antd/es/select";
import Col from "antd/es/grid/col";
import {
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { $clone } from "../../../../@vodea/utilities";
import {
  ACCESS_PERMISSION,
  env,
  INPUT_TYPE_CHECK_BOX,
  INPUT_TYPE_DROPDOWN,
  INPUT_TYPE_MULTIPLE_CHOICE,
  INPUT_TYPE_MULTIPLE_SHORT_ANSWER,
  INPUT_TYPE_OPTIONS,
  INPUT_TYPE_OPTION_LABELS,
  INPUT_TYPE_PARAGRAPH,
  INPUT_TYPE_RADIO,
  INPUT_TYPE_RADIO_SCALE,
  INPUT_TYPE_SHORT_ANSWER,
  INPUT_TYPE_TABLE,
} from "../../../../constant";
import VuiEditor, {
  vuiEditorBaseInit,
} from "../../../../@vodea/vodea-ui/components/Editor";
import { AxiosError, AxiosResponse } from "axios";
import CustomFormRepository from "../../../../repositories/CustomFormRepository";
import TextArea from "antd/es/input/TextArea";
import {
  handleBackendError,
  openNotification,
} from "../../../../functions/global";
import Accessible from "../../../../@vodea/vodea-ui/components/Accessible";

const { Option } = Select;

let title = "Form";

const AppCustomForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);

  const questionFormList: any = useState([]);
  const questionFormAnswers: any = useState({});

  const onFinish = async (data: any) => {
    loading.set(true);
    let questions = $clone(questionFormList.get());
    let questionAnswers = $clone(questionFormAnswers.get());
    let payload: any = {
      name: data.name,
      description: data.description,
      status: data.status ? "Active" : "Inactive",
      form_questions: questions.map((item: any) => {
        return {
          ...item,
          answers:
            item.type === INPUT_TYPE_SHORT_ANSWER
              ? []
              : questionAnswers[item.tempId],
          tempId: null,
        };
      }),
    };

    CustomFormRepository.update(id, payload)
      .then((res: AxiosResponse) => {
        navigate(-1);
        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    } else {
      initForm();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    disable.set(true);

    await CustomFormRepository.show(id, { with: ["formQuestions"] })
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};
        const modified = {
          status: data.status === "Active" ? true : false,
        };
        form.setFieldsValue({
          ...data,
          ...modified,
        });

        initForm(data.form_questions);
        disable.set(false);
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  const handleAddForm = () => {
    const tempId = Date.now().toString();
    const oldList = $clone(questionFormList.get());
    const list = [...oldList, { tempId, type: INPUT_TYPE_SHORT_ANSWER }];
    questionFormList.set(list);
  };

  const initForm = (data = []) => {
    if (data.length > 0) {
      let answers: any = {};
      const list = data.map((item: any) => {
        const tempId = item.id;
        answers[tempId] = item.answers;
        return {
          ...item,
          tempId,
        };
      });
      questionFormList.set(list);
      questionFormAnswers.set(answers);
    } else {
      const tempId = Date.now().toString();
      const list = [{ tempId, type: INPUT_TYPE_SHORT_ANSWER }];
      questionFormList.set(list);
    }
  };

  return (
    <>
      <VuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />
        <Form
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          initialValues={{
            questions: [{}],
          }}
        >
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
                order: 1,
              }}
              xs={{
                span: 24,
                order: 2,
              }}
            >
              <Card bodyStyle={{ paddingBottom: 0 }}>
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={5}
                >
                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="name"
                        label={"Category"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Category",
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Category",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="description"
                        label={"Description"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Description",
                            }),
                          },
                        ]}
                      >
                        <VuiEditor
                          disabled={disable.get()}
                          apiKey={env.getTinyApiKey()}
                          init={{
                            ...vuiEditorBaseInit,
                            placeholder: t("common.text.input", {
                              item: "Description",
                            }),
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="status"
                        label={"Status"}
                        valuePropName="checked"
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Status",
                            }),
                          },
                        ]}
                      >
                        <Switch disabled={disable.get()} />
                      </Form.Item>
                    </div>
                  </div>
                </Space>
              </Card>

              <br />

              <Space style={{ width: "100%" }} direction="vertical" size={25}>
                {questionFormList.get().map((item: any, index: number) => {
                  return (
                    <Card
                      bodyStyle={{ paddingTop: 0 }}
                      title={`Section #${index + 1}`}
                      className="card-title-required custom-form"
                      key={index}
                    >
                      <QuestionForm
                        data={item}
                        index={index}
                        key={`${item.tempId}`}
                        answers={questionFormAnswers}
                        disabled={disable.get()}
                        list={questionFormList}
                      />
                    </Card>
                  );
                })}
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  ghost
                  block
                  onClick={handleAddForm}
                >
                  Add Section
                </Button>
              </Space>
            </Col>
          </Row>

          <div className="vui-form-btn-group">
            <Button
              className="vui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.cancel")}
            </Button>
            <Accessible
              access={
                id
                  ? ACCESS_PERMISSION.form.update
                  : ACCESS_PERMISSION.form.store
              }
            >
              <Spin spinning={loading.get()}>
                <Button
                  className="vui-btn"
                  htmlType="submit"
                  type="primary"
                  size={"large"}
                  disabled={disable.get()}
                >
                  {t("common.button.save")}
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </VuiContainer>
    </>
  );
};

const QuestionForm = (props: any) => {
  const data = props.data || {};
  const index = props.index || 0;
  const questionFormList = props.list;
  const questionFormAnswers = props.answers;
  const answers = questionFormAnswers.get()[data.tempId] || [];
  const disabled = props.disabled;

  const handleFormChange = (value: any, key: string, index: number) => {
    let list = $clone(questionFormList.get());
    const oldValue = `${list[index][key]}`;
    list[index][key] = value;
    if (key === "type") {
      const tempId = list[index]["tempId"];
      let answerObj = $clone(questionFormAnswers.get());
      if (!answerObj[tempId]) {
        answerObj[tempId] = [""];
        questionFormAnswers.set(answerObj);
      }

      if (
        value === INPUT_TYPE_RADIO_SCALE &&
        Array.isArray(answerObj[tempId])
      ) {
        answerObj[tempId] = {
          scale: ["", ""],
          option: [""],
        };
        questionFormAnswers.set(answerObj);
      } else if (
        oldValue === INPUT_TYPE_TABLE ||
        oldValue === INPUT_TYPE_RADIO_SCALE
      ) {
        answerObj[tempId] = [""];
        questionFormAnswers.set(answerObj);
      }
    }
    questionFormList.set(list);
  };

  const handleDeleteForm = (tempId: string) => {
    const oldList = $clone(questionFormList.get());
    const list = oldList.filter((item: any) => item.tempId !== tempId);
    questionFormList.set(list);
  };

  const handleAddAnswerOption = (tempId: string, field: string = "") => {
    let answerObj = $clone(questionFormAnswers.get());
    if (field) {
      //this is radio scale
      answerObj[tempId][field] = [...answerObj[tempId][field], ""];
    } else {
      if (!Array.isArray(answerObj[tempId])) {
        answerObj[tempId] = [];
      }
      answerObj[tempId] = [...answerObj[tempId], ""];
    }
    questionFormAnswers.set(answerObj);
  };

  const handleDeleteAnswerOption = (
    tempId: string,
    index: number,
    field: string = ""
  ) => {
    let answerObj = $clone(questionFormAnswers.get());
    if (field) {
      answerObj[tempId][field].splice(index, 1);
    } else {
      answerObj[tempId].splice(index, 1);
    }
    questionFormAnswers.set(answerObj);
  };

  const handleAnswerChange = (
    value: string,
    tempId: string,
    index: number,
    field: string = ""
  ) => {
    let answerObj = $clone(questionFormAnswers.get());
    if (field) {
      if (!answerObj[tempId][field] && field === "scale") {
        answerObj[tempId][field] = ["", ""];
      }
      answerObj[tempId][field][index] = value;
    } else {
      answerObj[tempId][index] = value;
    }
    questionFormAnswers.set(answerObj);
  };

  return (
    <Space size={13} direction="vertical" style={{ width: "100%" }}>
      {questionFormList.get().length > 1 && (
        <div className="custom-form-delete">
          <DeleteOutlined
            style={{ fontSize: 18, paddingTop: 8 }}
            onClick={() => handleDeleteForm(data.tempId)}
          />
        </div>
      )}
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <div className="vui-form-group">
            <div className="input-section">
              <Select
                disabled={disabled}
                size={"large"}
                style={{
                  width: "100%",
                }}
                onChange={(value) => {
                  handleFormChange(value, "type", index);
                }}
                placeholder="Type"
                value={data.type}
              >
                {INPUT_TYPE_OPTIONS.map((item: any) => (
                  <Option key={item} value={item}>
                    {INPUT_TYPE_OPTION_LABELS[item]}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </Col>

        <Col flex="auto">
          <div className="vui-form-group">
            <div className="input-section">
              <Input
                disabled={disabled}
                onChange={(e) => {
                  handleFormChange(e.target.value, "question", index);
                }}
                size={"large"}
                placeholder="Question"
                defaultValue={data.question}
              />
            </div>
          </div>
        </Col>

        <Col xs={24}>
          <div className="vui-form-group">
            <div className="input-section">
              <Input
                disabled={disabled}
                onChange={(e) => {
                  handleFormChange(e.target.value, "tooltip", index);
                }}
                size={"large"}
                placeholder="Tooltip"
                defaultValue={data.tooltip}
              />
            </div>
          </div>
        </Col>
      </Row>

      <div className="vui-form-group">
        <div className="input-section">
          <Row>
            <Col span={4}>
              <Checkbox
                checked={Boolean(data.is_required)}
                disabled={disabled}
                onChange={(e) => {
                  handleFormChange(
                    e.target.checked ? 1 : 0,
                    "is_required",
                    index
                  );
                }}
              >
                Required
              </Checkbox>
            </Col>
            {data.type !== INPUT_TYPE_TABLE &&
              data.type !== INPUT_TYPE_MULTIPLE_SHORT_ANSWER &&
              (data.type === INPUT_TYPE_CHECK_BOX ||
                data.type === INPUT_TYPE_MULTIPLE_CHOICE ||
                data.type === INPUT_TYPE_RADIO) && (
                <Col span={6}>
                  <Checkbox
                    checked={Boolean(data.is_others)}
                    disabled={disabled}
                    onChange={(e) => {
                      handleFormChange(
                        e.target.checked ? 1 : 0,
                        "is_others",
                        index
                      );
                    }}
                  >
                    Add "Other" Option
                  </Checkbox>
                </Col>
              )}
          </Row>
        </div>
      </div>

      {data.type === INPUT_TYPE_TABLE && (
        <InputTable
          data={answers}
          tempId={data.tempId}
          questionFormAnswers={questionFormAnswers}
        />
      )}

      {data.type === INPUT_TYPE_RADIO_SCALE && (
        <Space size={10} direction="vertical" style={{ width: "100%" }}>
          <Row gutter={15}>
            <Col>
              <Input
                disabled={disabled}
                onChange={(e) => {
                  handleAnswerChange(e.target.value, data.tempId, 0, "scale");
                }}
                key={
                  answers.scale ? "answers.scale[0]" : "not.answers.scale[0]"
                }
                size={"large"}
                placeholder="From"
                defaultValue={answers.scale ? answers.scale[0] : ""}
              />
            </Col>
            <Col>
              <Input
                disabled={disabled}
                onChange={(e) => {
                  handleAnswerChange(e.target.value, data.tempId, 1, "scale");
                }}
                size={"large"}
                key={
                  answers.scale ? "answers.scale[1]" : "not.answers.scale[1]"
                }
                placeholder="To"
                defaultValue={answers.scale ? answers.scale[1] : ""}
              />
            </Col>
          </Row>
          {(answers?.option || []).map((value: any, idx: number) => (
            <Row gutter={[16, 0]} align="middle" key={`${data.tempId}_${idx}`}>
              <Col>
                <Radio checked={false}></Radio>
              </Col>

              <Col flex="auto">
                <Input
                  disabled={disabled}
                  size={"large"}
                  onBlur={(e) =>
                    handleAnswerChange(
                      e.target.value,
                      data.tempId,
                      idx,
                      "option"
                    )
                  }
                  placeholder="Option Text"
                  defaultValue={value}
                />
              </Col>

              <Col span={1}>
                {answers.length > 1 && (
                  <CloseOutlined
                    style={{ fontSize: 18 }}
                    onClick={() =>
                      handleDeleteAnswerOption(data.tempId, idx, "option")
                    }
                  />
                )}
              </Col>
            </Row>
          ))}
          <Button
            icon={<PlusOutlined />}
            type="primary"
            ghost
            onClick={() => handleAddAnswerOption(data.tempId, "option")}
          >
            Add Option
          </Button>
        </Space>
      )}

      {data.type === INPUT_TYPE_MULTIPLE_SHORT_ANSWER && (
        <InputTable
          data={answers}
          noColumn={true}
          tempId={data.tempId}
          questionFormAnswers={questionFormAnswers}
        />
      )}

      {data.type === INPUT_TYPE_SHORT_ANSWER && (
        <div className="input-section">
          <Input size={"large"} placeholder="Answer" disabled={true} />
        </div>
      )}

      {data.type === INPUT_TYPE_PARAGRAPH && (
        <div className="input-section">
          <VuiEditor
            apiKey={env.getTinyApiKey()}
            init={{
              ...vuiEditorBaseInit,
              placeholder: "Answer",
            }}
            disabled
          />
        </div>
      )}

      {(data.type === INPUT_TYPE_CHECK_BOX ||
        data.type === INPUT_TYPE_MULTIPLE_CHOICE ||
        data.type === INPUT_TYPE_DROPDOWN ||
        data.type === INPUT_TYPE_RADIO) && (
        <Space size={10} direction="vertical" style={{ width: "100%" }}>
          {(Array.isArray(answers) ? answers : []).map(
            (value: any, idx: number) => (
              <Row
                gutter={[16, 0]}
                align="middle"
                key={`${data.tempId}_${idx}`}
              >
                {data.type === INPUT_TYPE_CHECK_BOX && (
                  <Col>
                    <Checkbox checked={false}></Checkbox>
                  </Col>
                )}
                {(data.type === INPUT_TYPE_MULTIPLE_CHOICE ||
                  data.type === INPUT_TYPE_RADIO) && (
                  <Col>
                    <Radio checked={false}></Radio>
                  </Col>
                )}

                <Col flex="auto">
                  <Input
                    disabled={disabled}
                    size={"large"}
                    onBlur={(e) =>
                      handleAnswerChange(e.target.value, data.tempId, idx)
                    }
                    placeholder="Option Text"
                    defaultValue={value}
                  />
                </Col>

                <Col span={1}>
                  {answers.length > 1 && (
                    <CloseOutlined
                      style={{ fontSize: 18 }}
                      onClick={() => handleDeleteAnswerOption(data.tempId, idx)}
                    />
                  )}
                </Col>
              </Row>
            )
          )}

          {Boolean(data.is_others) && data.type !== INPUT_TYPE_DROPDOWN && (
            <Row gutter={[16, 0]} align="middle" key={`${data.tempId}_other`}>
              {data.type === INPUT_TYPE_CHECK_BOX && (
                <Col>
                  <Checkbox checked={false}></Checkbox>
                </Col>
              )}
              {(data.type === INPUT_TYPE_MULTIPLE_CHOICE ||
                data.type === INPUT_TYPE_RADIO) && (
                <Col>
                  <Radio checked={false}></Radio>
                </Col>
              )}
              <Col flex="auto">
                <Input
                  disabled={true}
                  size={"large"}
                  defaultValue='"Other" Answer'
                />
              </Col>

              <Col span={1}></Col>
            </Row>
          )}
          <Button
            icon={<PlusOutlined />}
            type="primary"
            ghost
            onClick={() => handleAddAnswerOption(data.tempId)}
          >
            Add Option
          </Button>
        </Space>
      )}
    </Space>
  );
};

const InputTable = (props: any) => {
  const { questionFormAnswers, tempId, noColumn } = props;
  const [columns, setColumns] = React.useState<string[]>(
    noColumn ? ["", ""] : []
  );
  const [rows, setRows] = React.useState<string[]>([]);

  enum Type {
    "COLUMN",
    "ROW",
  }
  const handleAdd = (type: Type) => {
    if (type === Type.COLUMN) {
      const newArr = [...columns, ""];
      setColumns(newArr);
    } else if (type === Type.ROW) {
      const newArr = [...rows, ""];
      setRows(newArr);
    }
  };

  const handleRemove = (type: Type, idx: number) => {
    if (type === Type.COLUMN) {
      let arr = [...columns];
      arr.splice(idx, 1);
      setColumns(arr);
    } else if (type === Type.ROW) {
      let arr = [...rows];
      arr.splice(idx, 1);
      setRows(arr);
    }
  };

  const handleChange = (value: string, idx: number, type: Type) => {
    let answerObj = $clone(questionFormAnswers.get());

    if (type === Type.COLUMN) {
      let newArr = [...columns];
      newArr[idx] = value;
      setColumns(newArr);
      const dataIdx = answerObj[tempId].findIndex(
        (item: any) => item?.type === "column"
      );
      if (dataIdx > -1) {
        answerObj[tempId][dataIdx]["list"][idx] = value;
      } else {
        answerObj[tempId] = [
          {
            type: "row",
            list: rows,
          },
          {
            type: "column",
            list: newArr,
          },
        ];
      }
    } else {
      let newArr = [...rows];
      newArr[idx] = value;
      setRows(newArr);
      if (!noColumn) {
        const dataIdx = answerObj[tempId].findIndex(
          (item: any) => item.type === "row"
        );
        if (dataIdx > -1) {
          answerObj[tempId][dataIdx]["list"][idx] = value;
        } else {
          answerObj[tempId] = [
            {
              type: "row",
              list: newArr,
            },
            {
              type: "column",
              list: columns,
            },
          ];
        }
      } else {
        answerObj[tempId] = newArr;
      }
    }

    questionFormAnswers.set(answerObj);
  };

  useEffect(() => {
    try {
      if (noColumn) {
        setColumns(["", ""]);
        setRows(
          Array.isArray(props.data) && typeof props.data[0] === "string"
            ? props.data
            : [""]
        );
      } else {
        const data: any[] = props.data || [];
        const colDataIdx: number = data.findIndex(
          (item: any) => item?.type === "column"
        );
        const rowDataIdx: number = data.findIndex(
          (item: any) => item?.type === "row"
        );
        setColumns(data[colDataIdx]?.list || []);
        setRows(data[rowDataIdx]?.list || []);
      }
    } catch (err) {
      console.log(err);
    }
  }, [props.data.length]);

  return (
    <Space size={10} direction="vertical" style={{ width: "100%" }}>
      <Space size={10} direction="horizontal">
        {!noColumn && (
          <Button type="primary" ghost onClick={() => handleAdd(Type.COLUMN)}>
            Add Column
          </Button>
        )}
        <Button type="primary" ghost onClick={() => handleAdd(Type.ROW)}>
          Add Row
        </Button>
      </Space>

      <table className="custom-form-table" width="100%">
        <thead>
          <tr>
            {!noColumn &&
              columns.map((item: any, idx: number) => (
                <th key={idx}>
                  <Input
                    size={"large"}
                    placeholder={`Column ${idx + 1}`}
                    key={item ? `item_col_${idx + 1}` : `notcol_${idx + 1}`}
                    defaultValue={item}
                    onBlur={(e) =>
                      handleChange(e.target.value, idx, Type.COLUMN)
                    }
                  />
                  <span onClick={() => handleRemove(Type.COLUMN, idx)}>
                    <CloseCircleOutlined />
                  </span>
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((item: any, idx: number) => (
            <tr key={idx}>
              <td>
                <Input
                  size={"large"}
                  placeholder={`Row ${idx + 1}`}
                  key={item ? `item_roe_${idx + 1}` : `not_${idx + 1}`}
                  defaultValue={item}
                  onBlur={(e) => handleChange(e.target.value, idx, Type.ROW)}
                />
                <span onClick={() => handleRemove(Type.ROW, idx)}>
                  <CloseCircleOutlined />
                </span>
              </td>
              {columns.map((item: any, idx: number) => {
                if (idx === 0) {
                  return null;
                } else {
                  return (
                    <td key={idx}>
                      <Input
                        size={"large"}
                        placeholder="Answer"
                        disabled={true}
                      />
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </Space>
  );
};

export default AppCustomForm;
