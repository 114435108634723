import { api, createCancelTokenHandler } from "../@vodea/services";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (params: any = null) {
    return api.get(`${endPoint()}/api/admin-psychologist/schedule`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/admin-psychologist/schedule/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  update: function (id: number | string, payload: any, params: any = null) {
    return api.put(
      `${endPoint()}/api/admin-psychologist/schedule/${id}`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
            .token,
      }
    );
  },
  session: function (id: number | string, payload: any, params: any = null) {
    return api.put(
      `${endPoint()}/api/admin-psychologist/schedule/trigger-session/${id}`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.session.name
          ].handleRequestCancellation().token,
      }
    );
  },
  extend: function (id: number | string, payload: any, params: any = null) {
    return api.put(
      `${endPoint()}/api/admin-psychologist/schedule/extend/${id}`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.extend.name].handleRequestCancellation()
            .token,
      }
    );
  },
  delete: function (id: number | string, params: any = null) {
    return api.delete(`${endPoint()}/api/admin-psychologist/schedule/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
          .token,
    });
  },
  form: function (params: any = null) {
    return api.get(`${endPoint()}/api/admin-psychologist/form`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.form.name].handleRequestCancellation()
          .token,
    });
  },
  formAssessment: function (params: any = null) {
    return api.get(`${endPoint()}/api/admin-psychologist/form-assessment`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.formAssessment.name
        ].handleRequestCancellation().token,
    });
  },
  history: function (params: any = null) {
    return api.get(`${endPoint()}/api/admin-psychologist/schedule-history`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
