import { api, createCancelTokenHandler } from "../@vodea/services";
import AppPageFrequentlyAskedQuestionForm from "../pages/App/Page/Form/FrequentlyAskedQuestion";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (params: any = null) {
    return api.get(`${endPoint()}/api/admin/page`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/admin/page/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  createHome: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/admin/page/home`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.createHome.name
        ].handleRequestCancellation().token,
    });
  },
  createAbout: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/admin/page/about`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.createAbout.name
        ].handleRequestCancellation().token,
    });
  },
  createBooking: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/admin/page/booking`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.createBooking.name
        ].handleRequestCancellation().token,
    });
  },
  createPsychologist: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/admin/page/psychologist`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.createPsychologist.name
        ].handleRequestCancellation().token,
    });
  },
  createService: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/admin/page/service`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.createService.name
        ].handleRequestCancellation().token,
    });
  },
  createEvent: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/admin/page/event`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.createEvent.name
        ].handleRequestCancellation().token,
    });
  },
  createInsight: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/admin/page/insight`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.createInsight.name
        ].handleRequestCancellation().token,
    });
  },
  createContact: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/admin/page/contact`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.createContact.name
        ].handleRequestCancellation().token,
    });
  },
  createTermsCondition: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/admin/page/terms-condition`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.createTermsCondition.name
        ].handleRequestCancellation().token,
    });
  },
  createFrequentlyAskedQuestion: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/admin/page/frequently-asked-question`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.createFrequentlyAskedQuestion.name
        ].handleRequestCancellation().token,
    });
  },
  createPrivacyPolicy: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/admin/page/privacy-policy`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.createPrivacyPolicy.name
        ].handleRequestCancellation().token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
