import { Helmet } from "react-helmet-async";
import VuiSectionTitle from "../../../../@vodea/vodea-ui/components/Sections/Title";
import Accessible from "../../../../@vodea/vodea-ui/components/Accessible";
import { ACCESS_PERMISSION, recentFilterKey } from "../../../../constant";
import Button from "antd/es/button";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useMemo } from "react";
import VuiContainer from "../../../../@vodea/vodea-ui/components/Container";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useIsMounted } from "../../../../@vodea/utilities/hooks";
import { useState } from "@hookstate/core";
import CustomerRepository from "../../../../repositories/CustomerRepository";
import { AxiosError, AxiosResponse } from "axios";
import {
  handleBackendError,
  openNotification,
  overideTableSortOrder,
  recentFilterData,
} from "../../../../functions/global";
import useDebounce from "../../../../@vodea/utilities/hooks/useDebounce";
import AssessmentRepository from "../../../../repositories/AssessmentRepository";
import Space from "antd/es/space";
import Text from "antd/es/typography/Text";
import Table from "antd/es/table";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import Input from "antd/es/input";
import VuiModalConfirmation from "../../../../@vodea/vodea-ui/components/Modal/Confirmation";
import VuiSelectMulti from "../../../../@vodea/vodea-ui/components/Select/Multi";
import PsychologistRepository from "../../../../repositories/PsychologistRepository";
import { Collapse, Select } from "antd";
import _ from "lodash";

const qs = require("qs");

const breadcrumbs = [
  {
    label: "Assessment & Programs",
    link: "/assessment",
  },
];

export const AppAssessmentList = () => {
  const pageTitle = "Assessment & Programs";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const [searchParams, setSearchParams] = useSearchParams();

  let fullPath = window.location.pathname;

  const tablePage = useState<number>(parseInt(searchParams.get("page") || "1"));
  const tablePerPage = useState<number>(
    parseInt(searchParams.get("perPage") || "10")
  );
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const tableSort = useState<{
    sortField: any;
    sortOrder: any;
  }>({
    sortField: searchParams.get("sortField"),
    sortOrder: searchParams.get("sortOrder"),
  });
  const tableData = useState([]);
  const totalData = useState<number>(0);

  const getTableData = async (reset: boolean = false) => {
    setTableLoading(true);

    if (reset) tablePage.set(1);

    let params = {
      page: tablePage.get(),
      search: debouncedSearch,
      order_by: tableSort.sortField.get(),
      sorted_by: tableSort.sortOrder.get(),
      per_page: tablePerPage.get(),
    };

    if (!params.order_by) {
      params.order_by = "name";
      params.sorted_by = "asc";
    }

    const psychologists = _.map(selectedPsychologist, "id");

    if (psychologists) {
      Object.assign(params, {
        psychologist_id: psychologists,
      });
    }

    if (selectedStatus) {
      Object.assign(params, {
        is_active: selectedStatus === "ACTIVE" ? 1 : 0,
      });
    }

    const types = _.map(selectedType, "id");
    if (types) {
      if (types.includes("Offline")) {
        Object.assign(params, {
          is_offline: 1,
        });
      }
      if (types.includes("Online")) {
        Object.assign(params, {
          is_online: 1,
        });
      }
      if (types.includes("No Schedule")) {
        Object.assign(params, {
          has_schedule: 0,
        });
      }
    }

    await AssessmentRepository.all(params)
      .then((res: AxiosResponse) => {
        if (res.data.data) {
          tableData.set(res.data.data);
        }

        totalData.set(res.data?.meta?.total || 0);

        setTableLoading(false);
      })
      .catch((e: AxiosError) => {
        setTableLoading(false);
      });

    setTableLoading(false);
  };

  // filter
  const recentPsychologist = useMemo(
    () => recentFilterData.get(recentFilterKey.psychologist),
    []
  );
  const [selectedPsychologist, setSelectedPsychologist] = React.useState<any[]>(
    []
  );
  const [selectedStatus, setSelectedStatus] = React.useState();
  const recentType = useMemo(
    () => recentFilterData.get(recentFilterKey.type),
    []
  );
  const [selectedType, setSelectedType] = React.useState<any[]>([]);

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    tablePage.set(pagination.current);
    tablePerPage.set(pagination.pageSize);

    if (sorter.order) {
      const sortOrder = overideTableSortOrder(sorter.order);
      tableSort.sortField.set(sorter.field);
      tableSort.sortOrder.set(sortOrder);
    } else {
      tableSort.sortField.set(null);
      tableSort.sortOrder.set(null);
    }

    setFilterDataToQuery();
  };

  // For Search Input
  const [search, setSearch] = React.useState<string>("");
  const debouncedSearch = useDebounce<string>(search, 1000);

  useEffect(() => {
    if (!isMounted) {
      setFilterDataToQuery();
    }
    // eslint-disable-next-line
  }, [debouncedSearch]);

  useEffect(() => {
    setFilterDataToQuery();
    // eslint-disable-next-line
  }, [selectedPsychologist, selectedStatus, selectedType]);

  const getFilterDataFromQuery = () => {
    const keyword = searchParams.get("keyword");

    if (keyword) {
      setSearch(keyword);
    }

    if (!recentPsychologist) {
      searchParams.delete("psychologist");
    } else {
      const psychologist = _.map(searchParams.getAll("psychologist"), (item) =>
        parseInt(item)
      );

      if (psychologist) {
        const psychologistFromStorage = recentFilterData.getSelectedFilter(
          recentPsychologist,
          psychologist
        );

        setSelectedPsychologist(psychologistFromStorage);
      } else {
        localStorage.removeItem(recentFilterKey.psychologist);
      }
    }

    if (!recentType) {
      searchParams.delete("type");
    } else {
      const type = _.map(searchParams.getAll("type"), (item) => parseInt(item));

      if (type) {
        const typeFromStorage = recentFilterData.getSelectedFilter(
          recentPsychologist,
          type
        );

        setSelectedPsychologist(typeFromStorage);
      } else {
        localStorage.removeItem(recentFilterKey.type);
      }
    }
  };

  useMemo(() => {
    getFilterDataFromQuery();
    // eslint-disable-next-line
  }, []);

  const setFilterDataToQuery = () => {
    let params = {};
    let reset = false;

    if (debouncedSearch) {
      Object.assign(params, {
        keyword: debouncedSearch,
      });

      if (debouncedSearch !== searchParams.get("keyword")) {
        reset = true;
      }
    }

    if (tablePage.get() !== 1) {
      if (reset) {
        Object.assign(params, {
          page: 1,
        });
      } else {
        Object.assign(params, {
          page: tablePage.get(),
        });
      }
    }

    if (tablePerPage.get() !== 10) {
      Object.assign(params, {
        perPage: tablePerPage.get(),
      });
    }

    if (tableSort.sortField.get()) {
      Object.assign(params, {
        sortField: tableSort.sortField.get(),
      });
    }

    if (tableSort.sortOrder.get()) {
      Object.assign(params, {
        sortOrder: tableSort.sortOrder.get(),
      });
    }

    if (selectedPsychologist) {
      const multiValue = _.map(selectedPsychologist, "id");

      Object.assign(params, {
        psychologist: multiValue,
      });
    }

    if (selectedType) {
      const multiValue = _.map(selectedType, "id");

      Object.assign(params, {
        type: multiValue,
      });
    }

    if (selectedStatus) {
      Object.assign(params, {
        status: selectedStatus,
      });
    }

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("");
    }

    getTableData(reset);
  };

  const columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "name" && tableSort.sortOrder.get(),
    },
    {
      title: "Psychologist",
      dataIndex: "psychologist_name",
      key: "psychologist_name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "psychologist_name" &&
        tableSort.sortOrder.get(),
    },
    {
      title: "Method",
      dataIndex: "is_offline",
      key: "is_offline",
      render: (value: string, record: any) => {
        let text = "-";
        if (record.is_online) {
          text = "Online";
          if (record.is_offline) {
            text = "Online, Face to Face";
          }
        } else {
          if (record.is_offline) {
            text = "Face to Face";
          }
        }
        return text;
      },
    },
    {
      title: "Number of Sessions",
      dataIndex: "number_of_sessions",
      key: "number_of_sessions",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "number_of_sessions" &&
        tableSort.sortOrder.get(),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "price" && tableSort.sortOrder.get(),
      render: (value: any) => {
        return `Rp. ${value.toLocaleString()}`;
      },
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "is_active" && tableSort.sortOrder.get(),
      render: (value: any) => `${value ? "Active" : "Inactive"}`,
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => {
        return (
          <Space size="middle">
            <Accessible access={ACCESS_PERMISSION.assessment.show}>
              <Link to={fullPath + "/" + record.id}>View</Link>
            </Accessible>
            <Accessible access={ACCESS_PERMISSION.assessment.destroy}>
              {record.can_delete && (
                <Text
                  className="cursor-pointer"
                  type={"danger"}
                  onClick={() => setDeleteModal(record.id)}
                >
                  Delete
                </Text>
              )}
            </Accessible>
          </Space>
        );
      },
    },
  ];

  // Modal
  const showDeleteModal = useState(false);
  const selectedDeleteId = useState(0);

  const setDeleteModal = (id: any) => {
    selectedDeleteId.set(id);
    showDeleteModal.set(true);
  };

  const deleteModalCallback = async () => {
    if (selectedDeleteId.get()) {
      await AssessmentRepository.delete(selectedDeleteId.get())
        .then((res: AxiosResponse) => {
          showDeleteModal.set(false);
          selectedDeleteId.set(0);
          openNotification(
            "success",
            t("notification.success.deleteItem", { item: "Assessment & Programs" })
          );
          getTableData(true);
        })
        .catch((e: AxiosError) => {
          showDeleteModal.set(false);
          selectedDeleteId.set(0);
          handleBackendError(e, t("notification.error.default"));
        });
    }
  };

  const handleReset = (e: any) => {
    e.stopPropagation();
    setSelectedPsychologist([]);
    setSelectedType([]);
    setSelectedStatus(undefined);
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <VuiContainer>
        <VuiSectionTitle title={pageTitle} breadcrumbs={breadcrumbs}>
          <Accessible access={ACCESS_PERMISSION.assessment.store}>
            <Button
              onClick={() => navigate("add")}
              type="primary"
              icon={<PlusOutlined />}
            >
              {t("common.button.addNew")}
            </Button>
          </Accessible>
        </VuiSectionTitle>

        <Collapse
          className="mb16"
          defaultActiveKey={["1"]}
          expandIconPosition="end"
        >
          <Collapse.Panel
            header="Filter"
            key="1"
            extra={
              <Button type="link" onClick={handleReset}>
                Reset
              </Button>
            }
          >
            <Row className="mb6" gutter={[10, 10]}>
              <Col xs={24} lg={8}>
                <VuiSelectMulti
                  value={selectedPsychologist}
                  onChange={(val: any) => {
                    tablePage.set(1);
                    setSelectedPsychologist(val);
                    recentFilterData.save(recentFilterKey.psychologist, val);
                  }}
                  repository={PsychologistRepository}
                  placeholder={t("select.placeholder", {
                    item: "Psychologist",
                  })}
                />
              </Col>
              <Col xs={24} lg={8}>
                <Select
                  style={{ width: "100%" }}
                  value={selectedStatus}
                  onChange={(val: any) => {
                    tablePage.set(1);
                    setSelectedStatus(val);
                  }}
                  options={[
                    {
                      label: "Active",
                      value: "ACTIVE",
                    },
                    {
                      label: "Inactive",
                      value: "INACTIVE",
                    },
                  ]}
                  placeholder={t("select.placeholder", {
                    item: "Status",
                  })}
                  size="large"
                  allowClear
                />
              </Col>
              <Col xs={24} lg={8}>
                <VuiSelectMulti
                  value={selectedType}
                  onChange={(val: any) => {
                    tablePage.set(1);
                    setSelectedType(val);
                    recentFilterData.save(recentFilterKey.type, val);
                  }}
                  options={[
                    {
                      name: "Online",
                      id: "Online",
                    },
                    {
                      name: "Face to Face",
                      id: "Offline",
                    },
                    {
                      name: "No Schedule",
                      id: "No Schedule",
                    },
                  ]}
                  placeholder={t("select.placeholder", {
                    item: "Method",
                  })}
                />
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>

        <Row gutter={[10, 10]} className="mb16">
          <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
            <Input
              allowClear
              placeholder={t("common.filter.search.placeholder")}
              prefix={<SearchOutlined />}
              value={search}
              onChange={(value) => {
                setSearch(value.target.value);
              }}
            />
          </Col>
        </Row>

        <Table
          rowKey={"id"}
          bordered
          columns={columns}
          dataSource={tableData.get()}
          loading={tableLoading}
          onChange={handleTableChange}
          pagination={{
            current: tablePage.get(),
            showSizeChanger: true,
            pageSize: tablePerPage.get(),
            total: totalData.get(),
          }}
        />
      </VuiContainer>

      <VuiModalConfirmation
        show={showDeleteModal.get()}
        onOk={deleteModalCallback}
        onCancel={() => showDeleteModal.set(false)}
      />
    </>
  );
};
