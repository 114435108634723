import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import VuiFormTitle from "../../../@vodea/vodea-ui/components/Form/Title";
import { ICounselingFormBaseProps } from "../interface";

const CounselingFormMultipleShortAnswer: React.FC<ICounselingFormBaseProps> = ({
  question,
  disabled,
}) => {
  const { t } = useTranslation();
  const formName = `${question.id}-${question.type}`;

  const answers: string[] = question.answers as string[];

  const initialValue = answers.map(() => "");

  return (
    <>
      <div className="mb8">
        <VuiFormTitle title={question.question} />
      </div>
      <Form.List name={formName} initialValue={initialValue}>
        {(fields) => (
          <>
            {fields.map((field, index) => {
              return (
                <div key={index} className="vui-form-group type-column">
                  <div className="input-section">
                    <Form.Item
                      className="multiple-short-answer-field"
                      {...field}
                      label={answers[index]}
                      rules={[
                        {
                          required: Boolean(question.is_required),
                          message: t("validation.required", {
                            item: "Answer",
                          }),
                        },
                      ]}
                      tooltip={question.tooltip}
                    >
                      <Input
                        disabled={disabled}
                        size={"large"}
                        placeholder={t("common.text.input", { item: "Answer" })}
                      />
                    </Form.Item>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </Form.List>
    </>
  );
};

export default CounselingFormMultipleShortAnswer;
