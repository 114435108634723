import AppLayout from "../layouts/App";
import { Navigate } from "react-router-dom";
import AppAccountProfile from "../pages/App/Account/Profile";
import AppAccountChangePassword from "../pages/App/Account/ChangePassword";
import AppPsychologistScheduleForm from "../pages/App/PsychologistSchedule/Form";
import AppDashboard from "../pages/App/Dashboard";
import AppReportList from "../pages/App/Report/List";
import AppReportView from "../pages/App/Report/View";
import AppContactFormList from "../pages/App/ContactForm/List";
import AppContactFormView from "../pages/App/ContactForm/View";
import AppSubscribeList from "../pages/App/Subscribe/List";
import AppSubscribeView from "../pages/App/Subscribe/View";
import AppSettings from "../pages/App/Settings";
import AppEventList from "../pages/App/Event/List";
import AppEventForm from "../pages/App/Event/Form";
import AppServiceList from "../pages/App/Service/List";
import AppServiceForm from "../pages/App/Service/Form";
import AppArticleList from "../pages/App/InsightAndResearch/Article/List";
import AppArticleForm from "../pages/App/InsightAndResearch/Article/Form";
import AppResearchList from "../pages/App/InsightAndResearch/Research/List";
import AppResearchForm from "../pages/App/InsightAndResearch/Research/Form";
import AppTagsList from "../pages/App/InsightAndResearch/Tags/List";
import AppTagsForm from "../pages/App/InsightAndResearch/Tags/Form";
import AppPsychologistList from "../pages/App/Psychologist/PsychologistList/List";
import AppPsychologistForm from "../pages/App/Psychologist/PsychologistList/Form";
import AppPsychologistExpertiseList from "../pages/App/Psychologist/Expertise/List";
import AppPsychologistExpertiseForm from "../pages/App/Psychologist/Expertise/Form";
import AppPsychologistCategoryList from "../pages/App/Psychologist/Category/List";
import AppPsychologistCategoryForm from "../pages/App/Psychologist/Category/Form";
import AppCustomerList from "../pages/App/Customer/List";
import AppCustomerView from "../pages/App/Customer/View";
import AppRoleList from "../pages/App/Admin/Role/List";
import AppRoleForm from "../pages/App/Admin/Role/Form";
import AppUserList from "../pages/App/Admin/User/List";
import AppUserForm from "../pages/App/Admin/User/Form";
import AppPageList from "../pages/App/Page/List";
import AppPageForm from "../pages/App/Page/Form";
import AppBookingView from "../pages/App/Booking/View";
import AppCustomForm from "../pages/App/CustomForm/Form";
import AppCustomFormList from "../pages/App/CustomForm/List";
import AppSocialList from "../pages/App/Social/List";
import AppSocialForm from "../pages/App/Social/Form";
import AppPackage from "../pages/App/Package";
import AppScheduleList from "../pages/App/Schedule/List";

import AppScheduleFormCreate from "../pages/App/Schedule/FormCreate";
import AppScheduleFormUpdate from "../pages/App/Schedule/FormUpdate";
import AppScheduleView from "../pages/App/Schedule/View";
import AppPsychologistScheduleHistoryList from "../pages/App/PsychologistScheduleHistory/List";
import AppPsychologistScheduleHistoryView from "../pages/App/PsychologistScheduleHistory/View";
import { AppAssessmentForm } from "../pages/App/Assessment/Form";
import { AppAssessmentList } from "../pages/App/Assessment/List";
import AppPsychologistScheduleList from "../pages/App/PsychologistSchedule/List";
import AppBookingList from "../pages/App/Booking/List";

const appRoutes = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      { path: "/", element: <Navigate to={"/dashboard"} /> },
      { path: "/dashboard", element: <AppDashboard /> },
      {
        path: "/schedule",
        children: [
          { path: "/", element: <AppScheduleList key="schedule" /> },
          { path: "/form", element: <AppScheduleFormCreate /> },
          { path: "/:id", element: <AppScheduleFormUpdate /> },
          { path: "/detail/:id", element: <AppScheduleView /> },
          {
            path: "/upcoming-schedule",
            children: [
              {
                path: "/",
                element: <AppPsychologistScheduleList />,
              },
              { path: "/:id", element: <AppPsychologistScheduleForm /> },
            ],
          },
          {
            path: "/schedule-history",
            children: [
              {
                path: "/",
                element: <AppPsychologistScheduleHistoryList key="history" />,
              },
              { path: "/:id", element: <AppPsychologistScheduleHistoryView /> },
            ],
          },
        ],
      },

      {
        path: "/report",
        children: [
          { path: "/", element: <AppReportList /> },
          { path: "/:year/:month", element: <AppReportView /> },
        ],
      },
      {
        path: "/event",
        children: [
          { path: "/", element: <AppEventList /> },
          { path: "/form", element: <AppEventForm /> },
          { path: "/:id", element: <AppEventForm /> },
        ],
      },
      {
        path: "/package",
        children: [{ path: "/", element: <AppPackage /> }],
      },
      {
        path: "/custom-form",
        children: [
          { path: "/", element: <AppCustomFormList /> },
          { path: "/form", element: <AppCustomForm /> },
          { path: "/:id", element: <AppCustomForm /> },
        ],
      },
      {
        path: "/assessment",
        children: [
          { path: "/", element: <AppAssessmentList /> },
          { path: "/add", element: <AppAssessmentForm /> },
          { path: "/:id", element: <AppAssessmentForm /> },
        ],
      },
      {
        path: "/service",
        children: [
          { path: "/", element: <AppServiceList /> },
          { path: "/form", element: <AppServiceForm /> },
          { path: "/:id", element: <AppServiceForm /> },
        ],
      },
      {
        path: "/contact-form",
        children: [
          { path: "/", element: <AppContactFormList /> },
          { path: "/:id", element: <AppContactFormView /> },
        ],
      },
      {
        path: "/page",
        children: [
          { path: "/", element: <AppPageList /> },
          { path: "/:id", element: <AppPageForm /> },
        ],
      },
      {
        path: "/customer",
        children: [
          { path: "/", element: <AppCustomerList /> },
          { path: "/:id", element: <AppCustomerView /> },
        ],
      },
      {
        path: "/subscribe",
        children: [
          { path: "/", element: <AppSubscribeList /> },
          { path: "/:id", element: <AppSubscribeView /> },
        ],
      },
      { path: "/settings", element: <AppSettings /> },
      {
        path: "/insight_and_research",
        children: [
          {
            path: "/",
            element: <Navigate to={"/insight_and_research/article"} />,
          },
          {
            path: "/article",
            children: [
              { path: "/", element: <AppArticleList /> },
              { path: "/form", element: <AppArticleForm /> },
              { path: "/:id", element: <AppArticleForm /> },
            ],
          },
          {
            path: "/research",
            children: [
              { path: "/", element: <AppResearchList /> },
              { path: "/form", element: <AppResearchForm /> },
              { path: "/:id", element: <AppResearchForm /> },
            ],
          },
          {
            path: "/tags",
            children: [
              { path: "/", element: <AppTagsList /> },
              { path: "/form", element: <AppTagsForm /> },
              { path: "/:id", element: <AppTagsForm /> },
            ],
          },
        ],
      },
      {
        path: "/psychologist",
        children: [
          {
            path: "/",
            element: <Navigate to={"/psychologist/psychologist-list"} />,
          },
          {
            path: "/psychologist-list",
            children: [
              { path: "/", element: <AppPsychologistList /> },
              { path: "/form", element: <AppPsychologistForm /> },
              { path: "/:id", element: <AppPsychologistForm /> },
            ],
          },
          {
            path: "/expertise",
            children: [
              { path: "/", element: <AppPsychologistExpertiseList /> },
              { path: "/form", element: <AppPsychologistExpertiseForm /> },
              { path: "/:id", element: <AppPsychologistExpertiseForm /> },
            ],
          },
          {
            path: "/psychologist-category",
            children: [
              { path: "/", element: <AppPsychologistCategoryList /> },
              { path: "/form", element: <AppPsychologistCategoryForm /> },
              { path: "/:id", element: <AppPsychologistCategoryForm /> },
            ],
          },
        ],
      },
      {
        path: "/booking",
        children: [
          { path: "/", element: <AppBookingList /> },
          { path: "/:id", element: <AppBookingView /> },
        ],
      },
      {
        path: "/account",
        children: [
          { path: "/", element: <Navigate to={"/account/profile"} /> },
          { path: "/profile", element: <AppAccountProfile /> },
          { path: "/change-password", element: <AppAccountChangePassword /> },
        ],
      },
      {
        path: "/admin",
        children: [
          { path: "/", element: <Navigate to={"/admin/user"} /> },
          {
            path: "/user",
            children: [
              { path: "/", element: <AppUserList /> },
              { path: "/form", element: <AppUserForm /> },
              { path: "/:id", element: <AppUserForm /> },
            ],
          },
          {
            path: "/role",
            children: [
              { path: "/", element: <AppRoleList /> },
              { path: "/form", element: <AppRoleForm /> },
              { path: "/:id", element: <AppRoleForm /> },
            ],
          },
        ],
      },
      {
        path: "/social",
        children: [
          { path: "/", element: <AppSocialList /> },
          { path: "/:id", element: <AppSocialForm /> },
        ],
      },
    ],
  },
];

export default appRoutes;
