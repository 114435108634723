import { useState } from "@hookstate/core";
import { DatePicker, Radio, TimePicker } from "antd";
import PageHeader from "antd/es/page-header";
import Card from "antd/lib/card";
import Form from "antd/lib/form";
import { Col, Row } from "antd/lib/grid";
import Space from "antd/lib/space";
import { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import VuiContainer from "../../../../@vodea/vodea-ui/components/Container";
import VuiSelectSingle from "../../../../@vodea/vodea-ui/components/Select/Single";
import { ACCESS_PERMISSION } from "../../../../constant";
import {
  handleBackendError,
  openNotification,
} from "../../../../functions/global";

import ScheduleRepository from "../../../../repositories/ScheduleRepository";
import PsychologistRepository from "../../../../repositories/PsychologistRepository";
import {
  scheduleSessionTypes,
  scheduleTypes,
} from "../../../../constant/schedule";
import _ from "lodash";
import { Schedule } from "../../../../entities/schedule";
import { Status } from "../../../../entities/status";
import { ScheduleUpdateFormData } from "./interface";
import { Resource } from "../../../../entities/base/resource";
import { ScheduleUpdatePayload } from "../../../../entities/dtos/schedule";
import StatusTag from "../../../../components/StatusTag";
import FormAction from "../../../../components/FormAction";

let title = "Schedule";

const timePickerFormat = "HH:mm";

const AppScheduleFormUpdate: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const pageTitle = t("common.text.editItem", { item: title });

  // Form
  const [form] = Form.useForm();

  const disable = useState(false);
  const isSubmitting = useState(false);
  const isLoading = useState(false);
  const [status, setStatus] = React.useState<Status>();

  const onFinish = async (data: ScheduleUpdateFormData) => {
    const { psychologist_id, type, session_type, date, time } = data ?? {};

    isSubmitting.set(true);

    const formData: ScheduleUpdatePayload = {
      psychologist_id: psychologist_id?.value as number,
      session_type: session_type,
      type: type,
      date: moment(date).format("YYYY-MM-DD"),
      time_from: moment(_.get(time, [0])).format("HH:mm"),
      time_to: moment(_.get(time, [1])).format("HH:mm"),
    };

    ScheduleRepository.update(id, formData)
      .then((res: AxiosResponse) => {
        navigate(-1);

        openNotification(
          "success",
          t("notification.success.updateItem", { item: title })
        );
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        isSubmitting.set(false);
      });
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    isLoading.set(true);
    const payload = {
      with: ["psychologist", "status"],
    };
    ScheduleRepository.show(id, payload)
      .then((res: AxiosResponse<Resource<Schedule>>) => {
        const data = res.data?.data || {};

        const modified: ScheduleUpdateFormData = {
          psychologist_id: {
            label: data.psychologist?.name,
            value: data.psychologist_id,
          },
          date: moment(data.date),
          time: [
            moment(data.time_from, "HH:mm:ss"),
            moment(data.time_to, "HH:mm:ss"),
          ],
          session_type: data.session_type,
          type: data.type,
        };

        form.setFieldsValue({
          ...modified,
        });

        setStatus(data.status);
      })
      .catch((e: AxiosError) => {
        handleBackendError(e);
        disable.set(true);
      })
      .finally(() => {
        isLoading.set(false);
      });
  };

  const onDelete = async () => {
    await ScheduleRepository.delete(id)
      .then((res: AxiosResponse) => {
        navigate(-1);
        openNotification(
          "success",
          t("notification.success.deleteItem", { item: title })
        );
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
      });
  };

  const renderLeftSection = () => {
    return (
      <Col xs={24} lg={8}>
        <Card loading={isLoading.get()}>
          <Space
            style={{
              width: "100%",
            }}
            direction="vertical"
            size={0}
          >
            <div className="vui-form-group type-column">
              <div className="input-section">
                <Form.Item
                  name="psychologist_id"
                  label={"Psychologist"}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: "Psychologist",
                      }),
                    },
                  ]}
                >
                  <VuiSelectSingle
                    disabled={disable.get()}
                    repository={PsychologistRepository}
                    placeholder={t("select.placeholder", {
                      item: "Psychologist",
                    })}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="vui-form-group type-column">
              <div className="input-section">
                <Form.Item
                  name="session_type"
                  label={"Type"}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: "Type",
                      }),
                    },
                  ]}
                >
                  <Radio.Group>
                    <Space direction="vertical">
                      {scheduleSessionTypes.map((item) => {
                        return (
                          <Radio key={item} value={item}>
                            {_.capitalize(item)}
                          </Radio>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
            <div className="vui-form-group type-column">
              <div className="input-section">
                <Form.Item
                  name="type"
                  label={"Method"}
                  rules={[
                    {
                      required: true,
                      message: t("validation.required", {
                        item: "Method",
                      }),
                    },
                  ]}
                >
                  <Radio.Group>
                    <Space direction="vertical">
                      {scheduleTypes.map((item) => {
                        return (
                          <Radio key={item} value={item}>
                            {_.capitalize(item)}
                          </Radio>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
          </Space>
        </Card>
      </Col>
    );
  };

  const renderRightSection = () => {
    return (
      <Col xs={24} lg={16}>
        <Card loading={isLoading.get()}>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={12}>
              <div className="vui-form-group type-column">
                <div className="input-section">
                  <Form.Item
                    name={"date"}
                    label="Date"
                    rules={[
                      {
                        required: true,
                        message: t("validation.required", {
                          item: "Date",
                        }),
                      },
                    ]}
                  >
                    <DatePicker
                      disabled={disable.get()}
                      showToday={false}
                      disabledDate={(current) => {
                        let customDate = moment().format("YYYY-MM-DD");
                        return (
                          current && current < moment(customDate, "YYYY-MM-DD")
                        );
                      }}
                      size="large"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col xs={24} lg={12}>
              <div className="vui-form-group type-column">
                <div className="input-section">
                  <Form.Item
                    name="time"
                    label="Time"
                    rules={[
                      {
                        required: true,
                        message: t("validation.required", {
                          item: "Time",
                        }),
                      },
                    ]}
                  >
                    <TimePicker.RangePicker
                      disabled={disable.get()}
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      format={timePickerFormat}
                    />
                  </Form.Item>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title} Detail</title>
      </Helmet>
      <VuiContainer bottomSpace={110}>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
          tags={status && <StatusTag title={_.get(status, "label", "")} />}
        />

        <Form
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          onFinishFailed={() =>
            openNotification("warning", t("notification.error.uncompleteForm"))
          }
        >
          <Row className="schedule-form-create-container" gutter={[20, 20]}>
            {renderLeftSection()}
            {renderRightSection()}
          </Row>

          <FormAction
            formName={title}
            onDelete={onDelete}
            deleteAccess={ACCESS_PERMISSION.schedule.destroy}
            onCancel={() => navigate(-1)}
            onSubmitting={isSubmitting.get()}
            submitAccess={ACCESS_PERMISSION.schedule.update}
          />
        </Form>
      </VuiContainer>
    </>
  );
};

export default AppScheduleFormUpdate;
