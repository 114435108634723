import { LoadingOutlined } from "@ant-design/icons";
import Upload from "antd/lib/upload";
import React, { useEffect } from "react";
import { toFileList } from "../../../../functions/global";
import useMediaService from "../../../utilities/hooks/useMediaService";

interface Props {
  value?: any[];
  disabled?: boolean;
  onChange?: (value: any[]) => void;
  max?: number;
  isUploadFirst?: boolean;
}

const VuiUpload: React.FC<Props> = ({
  value = [],
  onChange,
  disabled,
  max = 1,
  isUploadFirst,
}) => {
  const { storeMedia } = useMediaService();
  const [fileList, setFileList] = React.useState<any>(value);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    setFileList(value);
  }, [value.length]);

  const handleOnChange: any = ({ fileList: newFileList }: any) => {
    let originalsFileObjects = newFileList
      .map((item: any) => {
        return item?.originFileObj;
      })
      .filter((item: any) => item !== undefined);

    if (isUploadFirst && originalsFileObjects?.length > 0) {
      setIsLoading(true);
      const formData = {
        files: originalsFileObjects,
        path: "file",
        disk: "public",
      };

      storeMedia(formData)
        .then((response: any) => {
          const uploadedFiles = toFileList(response);
          setFileList([...fileList, ...uploadedFiles]);
          onChange?.([...fileList, ...uploadedFiles]);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setFileList(newFileList);
      onChange?.(newFileList);
    }
  };

  return (
    <>
      <Upload
        disabled={disabled}
        listType="picture-card"
        fileList={fileList}
        onChange={handleOnChange}
        beforeUpload={() => false}
      >
        {isLoading ? (
          <LoadingOutlined />
        ) : (
          fileList.length < max && !disabled && "+ Upload"
        )}
      </Upload>
    </>
  );
};

export default VuiUpload;
