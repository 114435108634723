import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import VuiFormTitle from "../../../@vodea/vodea-ui/components/Form/Title";
import { IAnswerTable, ICounselingFormBaseProps } from "../interface";

const CounselingFormTable: React.FC<ICounselingFormBaseProps> = ({
  question,
  disabled,
}) => {
  const { t } = useTranslation();
  const formName = `${question.id}-${question.type}`;

  const answers: IAnswerTable[] = question.answers as IAnswerTable[];

  const column: IAnswerTable =
    answers.find((answer: any) => answer.type === "column") ||
    ({} as IAnswerTable);

  const row: IAnswerTable =
    answers.find((answer: any) => answer.type === "row") ||
    ({} as IAnswerTable);

  const initialValue = row.list.map((item, i: number) => {
    const val: any = {};
    column.list.forEach((col: string, icol: number) => {
      val[icol] = "";
    });
    return val;
  });

  return (
    <>
      <div className="mb8">
        <VuiFormTitle
          title={question.question}
          required={Boolean(question.is_required)}
          tooltip={question.tooltip}
        />
      </div>
      <Form.List name={formName} initialValue={initialValue}>
        {(fields, { add: add, remove: remove }) => (
          <table style={{ tableLayout: "auto", width: "100%" }}>
            <thead className="ant-table-thead">
              <tr>
                {column?.list.map((item, index) =>
                  !question.has_first_column && !item && index === 0 ? null : (
                    <th key={index} className="ant-table-cell">
                      {item}
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              {fields.map(({ key, name }) => {
                return (
                  <tr key={key} className="ant-table-row ant-table-row-level-0">
                    {question.has_first_column && (
                      <td className="ant-table-cell">{row.list[name]}</td>
                    )}
                    {column?.list.map((col: string, icol: number) => {
                      if (question.has_first_column && icol === 0) {
                        return null;
                      } else {
                        if (!Boolean(col) && icol === 0) {
                          return null;
                        }
                      }

                      return (
                        <td key={icol} className="ant-table-cell">
                          <Form.Item
                            className="mb0"
                            name={[name, icol]}
                            rules={[
                              {
                                required: Boolean(question.is_required),
                                message: t("validation.required", {
                                  item: "Answer",
                                }),
                              },
                            ]}
                          >
                            <Input
                              disabled={disabled}
                              size={"large"}
                              placeholder={t("common.text.input", {
                                item: "Answer",
                              })}
                            />
                          </Form.Item>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </Form.List>
    </>
  );
};

export default CounselingFormTable;
