import React from "react";
import { useParams } from "react-router-dom";
import Error404 from "../../../../pages/Error/404";
import AppPageHomeForm from "./Home";
import AppPageAboutForm from "./About";
import AppPageBookingForm from "./Booking";
import AppPagePsychologistForm from "./Psychologist";
import AppPageServiceForm from "./Service";
import AppPageEventForm from "./Event";
import AppPageInsightForm from "./Insight";
import AppPageContactForm from "./Contact";
import AppPageTermsConditionForm from "./TermsCondition";
import AppPagePrivacyPolicyForm from "./PrivacyPolicy";
import AppPageFrequentlyAskedQuestionForm from "./FrequentlyAskedQuestion";

const AppPageForm: React.FC<any> = () => {
  const { id } = useParams();

  const render = () => {
    switch (id) {
      case "1":
        return <AppPageHomeForm />;
      case "2":
        return <AppPageAboutForm />;
      case "3":
        return <AppPageBookingForm />;
      case "4":
        return <AppPagePsychologistForm />;
      case "5":
        return <AppPageServiceForm />;
      case "6":
        return <AppPageEventForm />;
      case "7":
        return <AppPageInsightForm />;
      case "8":
        return <AppPageContactForm />;
      case "9":
        return <AppPageTermsConditionForm />;
      case "10":
        return <AppPagePrivacyPolicyForm />;
      case "11":
        return <AppPageFrequentlyAskedQuestionForm />;
      default:
        return (
          <section id={"auth-layout"}>
            <div className="container-wrapper">
              <div className="container">
                <Error404 />
              </div>
            </div>
          </section>
        );
    }
  };

  return <>{render()}</>;
};

export default AppPageForm;
