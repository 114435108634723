import "./style.less";
import React, { PropsWithChildren } from "react";

export type VuiContainerProps = {
  bottomSpace?: number;
};

const VuiContainer: React.FC<PropsWithChildren<VuiContainerProps>> = ({
  children,
  bottomSpace,
}) => {
  return (
    <div className="vui-container-wrapper">
      <div className="container" style={{ paddingBottom: bottomSpace }}>
        {children}
      </div>
    </div>
  );
};

export default VuiContainer;
