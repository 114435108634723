import { DeleteOutlined } from "@ant-design/icons/lib/icons";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { useState } from "@hookstate/core";
import { Select } from "antd";
import Button from "antd/es/button";
import Input from "antd/es/input";
import PageHeader from "antd/es/page-header";
import Card from "antd/lib/card";
import Form from "antd/lib/form";
import { Col, Row } from "antd/lib/grid";
import Space from "antd/lib/space";
import Spin from "antd/lib/spin";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Accessible from "../../../../../@vodea/vodea-ui/components/Accessible";
import VuiContainer from "../../../../../@vodea/vodea-ui/components/Container";
import VuiEditor, {
  vuiEditorBaseInit,
} from "../../../../../@vodea/vodea-ui/components/Editor";
import VuiUploadImages from "../../../../../@vodea/vodea-ui/components/UploadImages";
import {
  ACCESS_PERMISSION,
  env,
  GENDER_OPTIONS,
  IMAGE_RECOMMENDATION_SIZE,
} from "../../../../../constant";
import {
  handleBackendError,
  openNotification,
  toFileList,
} from "../../../../../functions/global";
import PageRepository from "../../../../../repositories/PageRepository";

const { TextArea } = Input;
const { Option } = Select;

let title = "Informational About Page";

const AppPageAboutForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);

  const onFinish = async (data: any) => {
    loading.set(true);

    const formData = {
      ...data,
      image: _.get(data, "image[0].url"),
      icon_vision: _.get(data, "icon_vision[0].url"),
      icon_mission: _.get(data, "icon_mission[0].url"),
      icon_campaign: _.get(data, "icon_campaign[0].url"),
      whys: data?.whys?.map((item: any) => {
        return {
          ...item,
          image: _.get(item, "image[0].url"),
        };
      }),
    };

    PageRepository.createAbout(formData)
      .then((res: AxiosResponse) => {
        navigate(-1);
        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        console.log(e);
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    await PageRepository.show(id)
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};

        if (!_.isEmpty(data)) {
          const modified: any = {
            ...data,
          };

          Object.keys(modified).forEach((key) => {
            if (
              key === "image" ||
              key === "icon_vision" ||
              key === "icon_mission" ||
              key === "icon_campaign"
            ) {
              modified[key] = toFileList({ url: data[key] });
            } else if (key === "whys") {
              modified[key] = data[key].map((item: any) => {
                return {
                  ...item,
                  image: toFileList({ url: item.image }),
                };
              });
            }
          });

          form.setFieldsValue({
            ...modified,
          });
        }
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  };

  return (
    <>
      <Helmet>
        <title>{title} Detail</title>
      </Helmet>
      <VuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          initialValues={{
            whys: [
              {
                title: "",
                image: [],
              },
            ],
            clients: [
              {
                name: "",
                description: "",
                gender: "",
                age: "",
              },
            ],
          }}
        >
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
              }}
              xs={{
                span: 24,
              }}
            >
              <Card title={""} className="mb16">
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={0}
                >
                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="meta_title"
                        label={"Page Title (SEO)"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Page Title (SEO)",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Page Title (SEO)",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="meta_description"
                        label={"Page Description (SEO)"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Page Description (SEO)",
                            }),
                          },
                        ]}
                      >
                        <TextArea
                          rows={3}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Page Description (SEO)",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="meta_keyword"
                        label={"Keywords (SEO)"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Keywords (SEO)",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Keywords (SEO)",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="title"
                        label={"Title About Us"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Title About Us",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Title About Us",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="summary"
                        label={"Summary About Us"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Summary About Us",
                            }),
                          },
                        ]}
                      >
                        <TextArea
                          rows={3}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Summary About Us",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        label={
                          <div>
                            Image About Us{" "}
                            <span className="optional-form-label">
                              (
                              {t("common.text.recommendedSize", {
                                item: IMAGE_RECOMMENDATION_SIZE
                                  .informationalPage.about.aboutUs,
                              })}
                              )
                            </span>
                          </div>
                        }
                        name="image"
                      >
                        <VuiUploadImages isUploadFirst />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="description"
                        label={"Description About Us"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Description About Us",
                            }),
                          },
                        ]}
                      >
                        <VuiEditor
                          disabled={disable.get()}
                          apiKey={env.getTinyApiKey()}
                          init={{
                            ...vuiEditorBaseInit,
                            placeholder: t("common.text.input", {
                              item: "Description About Us",
                            }),
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="title_vision"
                        label={"Title Our Vision"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Title Our Vision",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Title Our Vision",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="description_vision"
                        label={"Description Our Vision"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Description Our Vision",
                            }),
                          },
                        ]}
                      >
                        <VuiEditor
                          disabled={disable.get()}
                          apiKey={env.getTinyApiKey()}
                          init={{
                            ...vuiEditorBaseInit,
                            placeholder: t("common.text.input", {
                              item: "Description Our Vision",
                            }),
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        label={
                          <div>
                            Icon Our Vision{" "}
                            <span className="optional-form-label">
                              (
                              {t("common.text.recommendedSize", {
                                item: IMAGE_RECOMMENDATION_SIZE
                                  .informationalPage.about.ourVision,
                              })}
                              )
                            </span>
                          </div>
                        }
                        name="icon_vision"
                      >
                        <VuiUploadImages isUploadFirst />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="title_mission"
                        label={"Title Our Mission"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Title Our Mission",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Title Our Mission",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="description_mission"
                        label={"Description Our Mission"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Description Our Mission",
                            }),
                          },
                        ]}
                      >
                        <VuiEditor
                          disabled={disable.get()}
                          apiKey={env.getTinyApiKey()}
                          init={{
                            ...vuiEditorBaseInit,
                            placeholder: t("common.text.input", {
                              item: "Description Our Mission",
                            }),
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        label={
                          <div>
                            Icon Our Mission{" "}
                            <span className="optional-form-label">
                              (
                              {t("common.text.recommendedSize", {
                                item: IMAGE_RECOMMENDATION_SIZE
                                  .informationalPage.about.ourMission,
                              })}
                              )
                            </span>
                          </div>
                        }
                        name="icon_mission"
                      >
                        <VuiUploadImages isUploadFirst />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="title_campaign"
                        label={"Title Our Campaign"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Title Our Campaign",
                            }),
                          },
                        ]}
                      >
                        <Input
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Title Our Campaign",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="description_campaign"
                        label={"Description Our Campaign"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Description Our Campaign",
                            }),
                          },
                        ]}
                      >
                        <VuiEditor
                          disabled={disable.get()}
                          apiKey={env.getTinyApiKey()}
                          init={{
                            ...vuiEditorBaseInit,
                            placeholder: t("common.text.input", {
                              item: "Description Our Campaign",
                            }),
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="vui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        label={
                          <div>
                            Icon Our Campaign{" "}
                            <span className="optional-form-label">
                              (
                              {t("common.text.recommendedSize", {
                                item: IMAGE_RECOMMENDATION_SIZE
                                  .informationalPage.about.ourCampaign,
                              })}
                              )
                            </span>
                          </div>
                        }
                        name="icon_campaign"
                      >
                        <VuiUploadImages isUploadFirst />
                      </Form.Item>
                    </div>
                  </div>

                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                    size={15}
                  >
                    <Form.List name="whys">
                      {(fields, { add, remove }) => (
                        <Card
                          title={"Add Why Personal Growth"}
                          extra={
                            <Button
                              type="primary"
                              onClick={() => add()}
                              icon={<PlusOutlined />}
                            >
                              Add
                            </Button>
                          }
                        >
                          <Space
                            style={{
                              width: "100%",
                            }}
                            direction="vertical"
                            size={15}
                          >
                            {fields.map(({ key, name, ...restField }) => (
                              <Card
                                title={`Why ${name + 1}`}
                                key={key}
                                extra={
                                  <>
                                    {name !== 0 ? (
                                      <DeleteOutlined
                                        onClick={() => remove(name)}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </>
                                }
                              >
                                <div className="vui-form-group type-column">
                                  <div className="input-section">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "title"]}
                                      label={"Title"}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("validation.required", {
                                            item: "Title",
                                          }),
                                        },
                                      ]}
                                    >
                                      <Input
                                        size={"large"}
                                        placeholder={t("common.text.input", {
                                          item: "Title",
                                        })}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="vui-form-group type-column">
                                  <div className="input-section">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "image"]}
                                      label={
                                        <div>
                                          Image{" "}
                                          <span className="optional-form-label">
                                            (
                                            {t("common.text.recommendedSize", {
                                              item: IMAGE_RECOMMENDATION_SIZE
                                                .informationalPage.about.why,
                                            })}
                                            )
                                          </span>
                                        </div>
                                      }
                                      rules={[
                                        {
                                          required: true,
                                          message: t("validation.required", {
                                            item: "Image",
                                          }),
                                        },
                                      ]}
                                    >
                                      <VuiUploadImages isUploadFirst />
                                    </Form.Item>
                                  </div>
                                </div>
                              </Card>
                            ))}
                          </Space>
                        </Card>
                      )}
                    </Form.List>

                    <Form.List name="clients">
                      {(fields, { add, remove }) => (
                        <Card
                          title={"Add Our Happy Clients"}
                          extra={
                            <Button
                              type="primary"
                              onClick={() => add()}
                              icon={<PlusOutlined />}
                            >
                              Add
                            </Button>
                          }
                        >
                          <Space
                            style={{
                              width: "100%",
                            }}
                            direction="vertical"
                            size={15}
                          >
                            {fields.map(({ key, name, ...restField }) => (
                              <Card
                                title={`Client ${name + 1}`}
                                key={key}
                                extra={
                                  <>
                                    {name !== 0 ? (
                                      <DeleteOutlined
                                        onClick={() => remove(name)}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </>
                                }
                              >
                                <div className="vui-form-group type-column">
                                  <div className="input-section">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "name"]}
                                      label={"Name"}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("validation.required", {
                                            item: "Name",
                                          }),
                                        },
                                      ]}
                                    >
                                      <Input
                                        size={"large"}
                                        placeholder={t("common.text.input", {
                                          item: "Name",
                                        })}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="vui-form-group type-column">
                                  <div className="input-section">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "description"]}
                                      label={"Description"}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("validation.required", {
                                            item: "Description",
                                          }),
                                        },
                                      ]}
                                    >
                                      <VuiEditor
                                        disabled={disable.get()}
                                        apiKey={env.getTinyApiKey()}
                                        init={{
                                          ...vuiEditorBaseInit,
                                          placeholder: t("common.text.input", {
                                            item: "Description",
                                          }),
                                        }}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="vui-form-group type-column">
                                  <div className="input-section">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "gender"]}
                                      label={"Gender"}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("validation.required", {
                                            item: "Gender",
                                          }),
                                        },
                                      ]}
                                    >
                                      <Select
                                        labelInValue={false}
                                        size={"large"}
                                        placeholder="Select Gender"
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        {GENDER_OPTIONS.map((item: any) => (
                                          <Option
                                            value={item.value}
                                            key={item.value}
                                          >
                                            <div>{item?.label}</div>
                                          </Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </div>
                                </div>

                                <div className="vui-form-group type-column">
                                  <div className="input-section">
                                    <Form.Item
                                      {...restField}
                                      name={[name, "age"]}
                                      label={"Age"}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("validation.required", {
                                            item: "Age",
                                          }),
                                        },
                                      ]}
                                    >
                                      <Input
                                        size={"large"}
                                        type="number"
                                        placeholder={t("common.text.input", {
                                          item: "Age",
                                        })}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                              </Card>
                            ))}
                          </Space>
                        </Card>
                      )}
                    </Form.List>
                  </Space>
                </Space>
              </Card>
            </Col>
          </Row>

          <div className="vui-form-btn-group">
            <Button
              className="vui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Accessible access={ACCESS_PERMISSION.page.about}>
              <Spin spinning={loading.get()}>
                <Button
                  className="vui-btn"
                  type="primary"
                  htmlType="submit"
                  size={"large"}
                >
                  Submit
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </VuiContainer>
    </>
  );
};

export default AppPageAboutForm;
